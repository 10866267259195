import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../helpers/user";
import Image from "react-graceful-image";

import styles from "./index.module.scss";

const HeroNew = () => {
  const navigate = useNavigate();
  const enterBtnText = isLoggedIn() ? "Гра" : "Вхід";
  return (
    <section className={styles.bgGradient} id="home" tabIndex="-1">
      <div className={`${styles.padCnt} container`}>
        <h1 className={styles.head}>
          <Image className={styles.logo} src="/logo.svg" alt="" />
        </h1>
        <p className={styles.tagline}>
          Фентезі-футбол - це найпопулярніша у світі гра серед футбольних
          вболівальників, найцікавіший різновид прогнозів. Реальні футболісти
          набиратимуть очки для твоєї віртуальної команди!
        </p>
        <div className={styles.actions}>
          <Button
            onClick={() => navigate("/rules")}
            className={styles.ruleBtn}
            variant="danger"
            size="lg"
          >
            <i className="bi bi-file-ruled"></i>&nbsp;Правила
          </Button>
          <Button
            onClick={() => navigate("/home")}
            size="lg"
            className={`${styles.loginBtn}`}
            variant="primary"
          >
            <i className="bi bi-box-arrow-in-right"></i>&nbsp;{enterBtnText}
          </Button>
        </div>
      </div>
      <div className={styles.imgHolder}>
        <Image src="/landing.png" alt="phone" className={styles.img} />
      </div>
    </section>
  );
};

export default HeroNew;
