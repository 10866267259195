import React, { useContext, useState } from "react";
import Container from "../Container";
import styles from "./index.module.scss";
import { Dropdown, Button } from "react-bootstrap";
import { AuthContext } from "../../providers/AuthProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { isLoggedIn } from "../../helpers/user";
import { useNavigate, useLocation } from "react-router-dom";
import { tgLink } from "../../constants";
import Support from "../../modals/Support";
import MobileMenu from "../MobileMenu";

const Header = () => {
  const [supportPopup, setSupportPopup] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const { user } = useContext(AuthContext);
  const { monthIndex } = useContext(SettingsContext);
  const navigate = useNavigate();
  const isAdmin = Boolean(user?.me?.isAdmin);
  const location = useLocation();

  const showPopup = () => {
    setSupportPopup(true);
  };

  const onHidePopup = () => {
    setSupportPopup(false);
  };

  const onAppLogoClick = () => {
    if (
      [
        "/maintenance",
        "/support",
        "/administration",
        "/access-denied",
      ].includes(location.pathname)
    ) {
      window.location.href = "/home";
    } else {
      navigate("/home");
    }
  };

  const onPartnerLogoClick = () => {
    window.open("https://setantasports.com/uk?cid=faupl", "_blank");
  };

  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.headerCnt}>
          <img
            className={styles.logo}
            src="/logo.svg"
            alt=""
            onClick={onAppLogoClick}
          />

          {isLoggedIn() ? (
            <div className={styles.rightSide}>
              <div className={styles.partnerCnt}>
                <span className={styles.pcaption}>Партнер проєкту</span>
                <img
                  className={styles.plogo}
                  src="/setanta.png"
                  alt=""
                  onClick={onPartnerLogoClick}
                />
              </div>
              <div
                className={styles.hamburger}
                onClick={() => setMobileMenu(true)}
              >
                <i className="bi bi-list" style={{ fontSize: 36 }}></i>
              </div>

              <Dropdown className={styles.dropdown}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <i
                    style={{ fontSize: 18 }}
                    className="bi bi-menu-button-wide"
                  ></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {isAdmin && (
                    <>
                      <Dropdown.Item
                        onClick={() => navigate("/administration")}
                      >
                        <i className="bi bi-people-fill"></i>&nbsp;Адмінка
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}
                  <Dropdown.Item onClick={() => navigate("/leagues")}>
                    <i className="bi bi-list-task"></i>&nbsp;Клубні ліги
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/private-leagues")}>
                    <i className="bi bi-incognito"></i>&nbsp;Приватні ліги
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => navigate(`/standings/${monthIndex}`)}
                  >
                    <i className="bi bi-calendar3"></i>&nbsp;Рейтинг місяця
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => navigate("/rules")}>
                    <i className="bi bi-paperclip"></i>&nbsp;Правила гри
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/terms-of-service")}>
                    <i className="bi bi-file-check"></i>&nbsp;Угода користувача
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/privacy-policy")}>
                    <i className="bi bi-cookie"></i>&nbsp;Використання cookies
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => window.open(tgLink, "_blank")}>
                    <i className="bi bi-telegram"></i>&nbsp;Офіційний канал
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => showPopup()}>
                    <i className="bi bi-headset"></i>&nbsp;Техпідтримка
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => navigate("/logout")}>
                    <i className="bi bi-box-arrow-right"></i>&nbsp;Вийти
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <Button
              className={styles.white}
              variant="link"
              onClick={() => navigate("/login")}
            >
              Увійти
            </Button>
          )}
        </div>
      </Container>
      <Support show={supportPopup} onHide={onHidePopup} />
      <MobileMenu
        show={mobileMenu}
        handleClose={() => setMobileMenu(false)}
        showSupportPopup={showPopup}
      />
    </header>
  );
};

export default Header;
