const removeEvent = (state, payload) => {
  const { eventIndex } = payload;
  return {
    ...state,
    events: state.events.filter((event, index) => index !== eventIndex),
  };
};

const addEvent = (state, payload) => {
  const { eventData } = payload;
  return {
    ...state,
    events: [...state.events, eventData],
  };
};

const updateEvent = (state, payload) => {
  const { eventIndex, eventData } = payload;
  return {
    ...state,
    events: state.events.map((event, index) =>
      index === eventIndex ? eventData : event
    ),
  };
};

const addHomeSquadPlayer = (state, payload) => {
  return {
    ...state,
    lineups: [
      {
        ...state.lineups[0],
        startXI: [
          ...state.lineups[0].startXI,
          {
            player: {
              id: null,
            },
          },
        ],
      },
      state.lineups[1],
    ],
  };
};

const addHomeBenchPlayer = (state, payload) => {
  return {
    ...state,
    lineups: [
      {
        ...state.lineups[0],
        substitutes: [
          ...state.lineups[0].substitutes,
          {
            player: {
              id: null,
            },
          },
        ],
      },
      state.lineups[1],
    ],
  };
};

const addAwaySquadPlayer = (state, payload) => {
  return {
    ...state,
    lineups: [
      state.lineups[0],
      {
        ...state.lineups[1],
        startXI: [
          ...state.lineups[1].startXI,
          {
            player: {
              id: null,
            },
          },
        ],
      },
    ],
  };
};

const addAwayBenchPlayer = (state, payload) => {
  return {
    ...state,
    lineups: [
      state.lineups[0],
      {
        ...state.lineups[1],
        substitutes: [
          ...state.lineups[1].substitutes,
          {
            player: {
              id: null,
            },
          },
        ],
      },
    ],
  };
};

const removePlayer = (state, payload) => {
  const { playerIndex, playerType } = payload;

  switch (playerType) {
    case "homeSquad":
      return {
        ...state,
        lineups: [
          {
            ...state.lineups[0],
            startXI: state.lineups[0].startXI.filter(
              (entry, index) => index !== playerIndex
            ),
          },
          state.lineups[1],
        ],
      };

    case "homeBench":
      return {
        ...state,
        lineups: [
          {
            ...state.lineups[0],
            substitutes: state.lineups[0].substitutes.filter(
              (entry, index) => index !== playerIndex
            ),
          },
          state.lineups[1],
        ],
      };

    case "awaySquad":
      return {
        ...state,
        lineups: [
          state.lineups[0],
          {
            ...state.lineups[1],
            startXI: state.lineups[1].startXI.filter(
              (entry, index) => index !== playerIndex
            ),
          },
        ],
      };
    case "awayBench":
      return {
        ...state,
        lineups: [
          state.lineups[0],
          {
            ...state.lineups[1],
            substitutes: state.lineups[1].substitutes.filter(
              (entry, index) => index !== playerIndex
            ),
          },
        ],
      };
    default:
      throw new Error();
  }
};

const updatePlayer = (state, payload) => {
  const { playerIndex, playerType, playerId } = payload;

  switch (playerType) {
    case "homeSquad":
      return {
        ...state,
        lineups: [
          {
            ...state.lineups[0],
            startXI: state.lineups[0].startXI.map((entry, index) =>
              index === playerIndex
                ? {
                    player: {
                      id: playerId,
                    },
                  }
                : entry
            ),
          },
          state.lineups[1],
        ],
      };

    case "homeBench":
      return {
        ...state,
        lineups: [
          {
            ...state.lineups[0],
            substitutes: state.lineups[0].substitutes.map((entry, index) =>
              index === playerIndex
                ? {
                    player: {
                      id: playerId,
                    },
                  }
                : entry
            ),
          },
          state.lineups[1],
        ],
      };

    case "awaySquad":
      return {
        ...state,
        lineups: [
          state.lineups[0],
          {
            ...state.lineups[1],
            startXI: state.lineups[1].startXI.map((entry, index) =>
              index === playerIndex
                ? {
                    player: {
                      id: playerId,
                    },
                  }
                : entry
            ),
          },
        ],
      };
    case "awayBench":
      return {
        ...state,
        lineups: [
          state.lineups[0],
          {
            ...state.lineups[1],
            substitutes: state.lineups[1].substitutes.map((entry, index) =>
              index === playerIndex
                ? {
                    player: {
                      id: playerId,
                    },
                  }
                : entry
            ),
          },
        ],
      };
    default:
      throw new Error();
  }
};

export default function jsonReducer(state, action) {
  switch (action.type) {
    case "set":
      return action.payload;
    case "updateEvent":
      return updateEvent(state, action.payload);
    case "removeEvent":
      return removeEvent(state, action.payload);
    case "addEvent":
      return addEvent(state, action.payload);
    case "addHomeSquadPlayer":
      return addHomeSquadPlayer(state, action.payload);
    case "addHomeBenchPlayer":
      return addHomeBenchPlayer(state, action.payload);
    case "addAwaySquadPlayer":
      return addAwaySquadPlayer(state, action.payload);
    case "addAwayBenchPlayer":
      return addAwayBenchPlayer(state, action.payload);
    case "removePlayer":
      return removePlayer(state, action.payload);
    case "updatePlayer":
      return updatePlayer(state, action.payload);
    default:
      throw new Error();
  }
}
