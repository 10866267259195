import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { clearLocalStorage } from "../../helpers/user";
import { useAuth0 } from "@auth0/auth0-react";
import { isAuth0 } from "../../helpers/auth";
import ClientCache from "../../cache";

const Logout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();

  useEffect(() => {
    const auth0 = isAuth0();
    clearLocalStorage();
    const cache = ClientCache.getInstance();
    cache.clear();
    if (auth0) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      navigate("/");
    } else {
      navigate("/");
    }
  }, [location, navigate, logout]);
  return null;
};

export default Logout;
