import React from "react";
import Container from "./Container";

const Page = ({
  children,
  notPadding = false,
  isHome = false,
  zeroPadding = false,
}) => {
  return (
    <Container
      style={{
        paddingTop: zeroPadding ? 0 : isHome ? 60 : notPadding ? 16 : 60,
        paddingBottom: zeroPadding ? 0 : isHome ? 74 : notPadding ? 16 : 48,
      }}
    >
      {children}
    </Container>
  );
};

export default Page;
