import { baseAuthUrl } from "../api";

export const isAuth0 = () => localStorage.getItem("authType") === "auth0";

export const goToLogin = (authType, loginWithRedirect = () => undefined) => {
  const auth0 = isAuth0(authType);
  if (auth0) {
    loginWithRedirect();
  } else {
    window.location.href = `${baseAuthUrl}/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_PATREON_CLIENT_ID}&redirect_uri=${window.location.origin}/login`;
  }
};
