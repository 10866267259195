import React from "react";
import styles from "./index.module.scss";

const Container = ({ style = {}, children }) => {
  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  );
};

export default Container;
