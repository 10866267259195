import React, { useState, useEffect } from "react";
import API from "../api";
import BackButton from "../components/BackButton";
import Page from "../components/Page";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import DetailsView from "../components/DetailsView";
import ScrollToTop from "../components/ScrollToTop";

const Details = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const { userId } = useParams();

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const res = await API.fetchUserDetails(userId);
      setItems(res.data.items);
      setUser(res.data.user);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails(userId);
    //eslint-disable-next-line
  }, [userId]);

  useEffect(() => {}, []);
  return (
    <Page>
      <div className="page-bar">
        <div className="inner">
          <ScrollToTop />
          <BackButton />
        </div>
      </div>
      {loading ? <Loader /> : <DetailsView user={user} items={items} />}
    </Page>
  );
};

export default Details;
