import React, { useState } from "react";
import EditPlayer from "../EditPlayer";
import Offcanvas from "react-bootstrap/Offcanvas";
import ListView from "../ListView";
import { useTable, usePagination } from "react-table";

const ListViewContainer = ({
  data,
  columns,
  players,
  setPlayers,
  setFilteredPlayers,
  isAdmin = false,
  allowedPosition = "all",
  disallowedTeams = [],
  onClick = () => undefined,
}) => {
  const [show, setShow] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const onClickRow = async (player) => {
    onClick(player);
    setSelectedPlayer(player);
    handleShow();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const table = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );
  return (
    <>
      <ListView
        table={table}
        onClickRow={onClickRow}
        allowedPosition={allowedPosition}
        disallowedTeams={disallowedTeams}
      />
      {isAdmin && (
        <Offcanvas
          style={{ height: "50vh" }}
          show={show}
          onHide={handleClose}
          placement="bottom"
        >
          <Offcanvas.Header
            style={{ justifyContent: "flex-end" }}
            closeButton
          ></Offcanvas.Header>{" "}
          <Offcanvas.Body style={{ paddingTop: 0 }}>
            {selectedPlayer && (
              <EditPlayer
                selectedPlayer={selectedPlayer}
                players={players}
                filteredPlayers={data}
                setFilteredPlayers={setFilteredPlayers}
                setPlayers={setPlayers}
                page={table.state.pageIndex}
                gotoPage={table.gotoPage}
                handleClose={handleClose}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default ListViewContainer;
