import React, { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";
import styles from "./index.module.scss";
import API from "../../api";
import { useMutation } from "react-query";
import { AdminContext } from "../../providers/AdminProvider";
import { SquadContext } from "../../providers/SquadProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import {
  isSquadReadyToPlay,
  getSquadMessage,
  isValidPlayerTeams,
  isValidPlayerPositions,
} from "../../helpers/squad";
import isEqual from "lodash.isequal";
import { MessageContext } from "../../providers/MessageProvider";
import useIsMobile from "../../hooks/useIsMobile";

const Submit = ({ isPwa = false, isHistoricalRound }) => {
  const { players } = useContext(AdminContext);

  const {
    squad,
    squadSnapshot,
    squadStack,
    setSquadStack,
    flatList,
    playersNumber,
    setSquadSnapshot,
    setSquad,
    setTeamBalance,
    setTeamBalanceSnapshot,
    setAllowedTransfers,
    setAllowedTransfersSnapshot,
    action,
    clearAction,
  } = useContext(SquadContext);

  const {
    activeDeadline,
    numberOfTeamPlayers,
    currentRound,
    setSelectedRound,
  } = useContext(SettingsContext);

  const { showMessage } = useContext(MessageContext);

  const isMobile = useIsMobile();

  const isFullSquad = isSquadReadyToPlay(flatList, playersNumber, squad);

  const undo = () => {
    if (action === "substitute") {
      clearAction();
    } else {
      squadStack.pop();
      const newSquad = squadStack.pop();
      setSquad(newSquad);
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (squad) => {
      return API.saveSquad(squad);
    },
    onSuccess: (response, variables, context) => {
      const squadResponse = JSON.parse(response.data.Squad);
      setSquadStack([]);
      setSquadSnapshot(squadResponse);
      setSquad(squadResponse);
      setTeamBalance(response.data.Balance);
      setTeamBalanceSnapshot(response.data.Balance);
      setAllowedTransfers(response.data.AllowedTransfers);
      setAllowedTransfersSnapshot(response.data.AllowedTransfers);
      showMessage("warning", "Ваші зміни у складі успішно збережено!");
    },
    onError: () => {
      undo();
      showMessage(
        "danger",
        "Помилка при збереженні складу! Спробуйте ще раз або зверніться до організаторів гри."
      );
    },
  });

  const [icon, squadMessage] = getSquadMessage(
    flatList,
    playersNumber,
    squad,
    squadSnapshot,
    activeDeadline,
    players,
    numberOfTeamPlayers,
    currentRound
  );

  const isBlinkingIcon = [
    "exclamation-circle-fill",
    "exclamation-octagon-fill",
  ].includes(icon);

  return (
    <div
      className={`${styles.submitCnt} ${isPwa ? styles.pwa : ""} ${
        !activeDeadline ? styles.dangerBg : ""
      }`}
    >
      <div className={styles.submitPanel}>
        {isHistoricalRound && activeDeadline ? (
          <Button
            className={styles.submit}
            onClick={() => setSelectedRound(currentRound)}
          >
            <i className="bi bi-pencil-square"></i>&nbsp;ПЕРЕЙТИ ДО РЕДАГУВАННЯ
            СКЛАДУ
          </Button>
        ) : (
          <>
            <div className={styles.warning}>
              <i
                className={`bi bi-${icon} ${isBlinkingIcon ? "blink-me" : ""}`}
                style={{ fontSize: "2rem", marginRight: 8 }}
              ></i>
              {squadMessage}
            </div>
            <div className={styles.buttonsCnt}>
              {(squadStack.length > 1 || action === "substitute") && (
                <Button variant="light" className={styles.undo} onClick={undo}>
                  <i className="bi bi-arrow-counterclockwise"></i>&nbsp;
                  {!isMobile ? "Скасувати зміну" : ""}
                </Button>
              )}
              {activeDeadline && !isEqual(squad, squadSnapshot) && (
                <Button
                  className={styles.submit}
                  variant="warning"
                  onClick={() => mutate(squad)}
                  disabled={
                    isLoading ||
                    !isFullSquad ||
                    !isValidPlayerTeams(squad, players, numberOfTeamPlayers) ||
                    !isValidPlayerPositions(squad, players)
                  }
                >
                  {!isLoading && (
                    <>
                      <i className="bi bi-cloud-upload"></i>&nbsp;
                    </>
                  )}
                  {isLoading && (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;
                    </>
                  )}
                  {isLoading ? "Зберігаю..." : "Зберегти"}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Submit;
