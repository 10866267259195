import React, { useState, useEffect, useContext } from "react";
import API from "../api";
import BackButton from "../components/BackButton";
import Page from "../components/Page";
import StandingsView from "../components/StandingsView";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import ScrollToTop from "../components/ScrollToTop";
import { getMonthLabel } from "../helpers/user";
import FindMe from "../components/FindMe";
import ClientCache from "../cache";

const getStandingsLabel = (round) => {
  const roundNumber = Number(round);

  if (Number.isNaN(roundNumber)) return getMonthLabel(round)?.toUpperCase();
  return roundNumber === -1 ? "CЕЗОН" : `ТУР ${roundNumber}`;
};

const Standings = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);

  const { round } = useParams();

  const fetchStandings = async () => {
    const targetRound = Number(round) === -1 ? undefined : round;
    const clientCache = ClientCache.getInstance();
    const cachedItems = clientCache.get(targetRound);

    try {
      if (cachedItems !== null) {
        setItems(cachedItems);
      } else {
        setLoading(true);
        const res = await API.fetchStandings(targetRound);
        setItems(res.data.standings);
        clientCache.set(targetRound, res.data.standings);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setItems([]);
    }
  };

  const hasMe =
    items.filter((item) => item.UserId === user?.me?.userId)?.length > 0;

  useEffect(() => {
    fetchStandings();
    //eslint-disable-next-line
  }, []);

  return (
    <Page>
      <div className="page-bar">
        <div className="inner">
          <ScrollToTop />
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackButton />
            <div className="bar-title">{getStandingsLabel(round)}</div>
          </div>
          {hasMe && <FindMe user={user} items={items} setItems={setItems} />}
        </div>
      </div>
      {loading ? <Loader /> : <StandingsView items={items} />}
    </Page>
  );
};

export default Standings;
