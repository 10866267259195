import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "./providers/AuthProvider";
import interceptor from "./api/client";
import AdminProvider from "./providers/AdminProvider";
import MessageProvider from "./providers/MessageProvider";
import SquadProvider from "./providers/SquadProvider";
import SettingsProvider from "./providers/SettingsProvider";
import { Auth0Provider } from "@auth0/auth0-react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

interceptor();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    useRefreshTokensFallback={true}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/login`,
      audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
      scope: "read:current_user",
    }}
  >
    <Router>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider>
          <AuthProvider>
            <AdminProvider>
              <SquadProvider>
                <MessageProvider>
                  <App />
                </MessageProvider>
              </SquadProvider>
            </AdminProvider>
          </AuthProvider>
        </SettingsProvider>
      </QueryClientProvider>
    </Router>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
