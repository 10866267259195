import React from "react";
import Page from "../Page";
import BackButton from "../BackButton";
import { tgLink } from "../../constants";
import ScrollToTop from "../ScrollToTop";

const TermsOfUse = () => {
  return (
    <Page>
      <ScrollToTop />
      <div className="page-bar">
        <div className="inner">
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackButton />
            <h4 className="bar-title">Угода користувача</h4>
          </div>
        </div>
      </div>
      <div className="page-offset">
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-check-lg"></i>&nbsp;
          <strong>Правила та умови </strong>
        </h5>

        <p>
          Будь ласка, уважно прочитайте загальні положення та умови перед
          використанням веб-додатку:{" "}
          <a href={`https://${process.env.REACT_APP_DOMAIN}`}>
            https://{process.env.REACT_APP_DOMAIN}
          </a>
        </p>
        <p>
          Ваш доступ до сервісу та його використання залежить від прийняття та
          дотримання умов визначених в цьому документі. Ці умови застосовуються
          до всіх відвідувачів, користувачів та інших осіб, які мають доступ або
          користуються сервісом. Отримуючи доступ або користуючись сервісом{" "}
          <strong>{process.env.REACT_APP_FANTASY_TITLE}</strong>, Ви
          погоджуєтесь дотримуватися цих умов. Якщо Ви не згодні з будь-якою
          частиною умов, ви не можете отримати доступ до сервісу.
        </p>
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-check-lg"></i>&nbsp;
          <strong>Участь у грі</strong>
        </h5>

        <p>
          Участь у грі є добровільною. Приймаючи участь у грі Ви погоджуєтеся з
          тим, що вся ігрова інформація (фото, повне ім’я, результати гри тощо),
          яка стосується вашого гравця, є публічною, доступною для перегляду
          іншими користувачами веб сервісу, а також може бути викорстана в
          публікаціях у соціальних мережах. Така інформація не може бути
          видалена за вашою вимогою. За вимогою можуть бути видалені виключно
          Ваші персональні дані (Фото, Iм’я).
        </p>
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-check-lg"></i>&nbsp;
          <strong>Посилання на інші веб-сайти</strong>
        </h5>

        <p>
          Застосунок <strong>{process.env.REACT_APP_FANTASY_TITLE}</strong> може
          містити посилання на інші веб-сайти чи послуги третіх сторін, які не
          належать або не контролюються розробниками цього застосунку.
          Розробники гри не несуть відповідальності за зміст, політику
          конфіденційності будь-яких веб сервісів або сервісів третіх сторін. Ви
          також визнаєте та погоджуєтеся з тим, що автори гри{" "}
          <strong>{process.env.REACT_APP_FANTASY_TITLE}</strong> не несуть
          прямої або опосередкованої відповідальності за будь-які збитки або
          втрати, що спричинені внаслідок використання сервісів, що надаються
          такими сайтами.{" "}
        </p>
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-check-lg"></i>&nbsp;
          <strong>Зміни</strong>
        </h5>
        <p>
          Ми залишаємо за собою право на власний розсуд змінювати ці умови в
          будь-який час. Якщо зміни будуть значними, ми намагатимемося
          повідомити користувачів принаймні за 30 днів щодо набрання чинності
          нових правил та умов використання веб додатку. Суттєвість змін буде
          визначатися на наш власний розсуд. Комунікація з користувачами може
          проводитись як через веб додаток, так і наші соціальні ресурси
          Patreon, Facebook, Instagram, Telegram.
        </p>
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-check-lg"></i>&nbsp;
          <strong>Зв'язок з нами</strong>
        </h5>
        <p>
          {" "}
          Якщо ви бажаєте надіслати звернення або у вас виникли запитання щодо
          цих правил та умов, зв’яжіться з нами будь-якими зручними каналами для
          комунікації (через Telegram, Patreon, Facebook, Instagram). Посилання
          на офіційний канал комунікації:{" "}
          <a href={tgLink} target="_blank" rel="noreferrer">
            тут
          </a>{" "}
        </p>
      </div>
    </Page>
  );
};

export default TermsOfUse;
