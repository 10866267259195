class ClientCache {
  // ttl 5min; interval 30sec
  constructor(ttl = 360000, timerInterval = 30000) {
    this.ttl = ttl;
    this.timerInterval = timerInterval;
    this.keyPreffix = "cache_";
    this.run();
    this.timer = setInterval(this.run.bind(this), timerInterval);
  }
  set(key, payload) {
    const storageKey = this.getStorageKey(key);
    window.sessionStorage.setItem(
      storageKey,
      JSON.stringify({ expire: this.getExpirationTime(), payload })
    );
  }
  get(key) {
    const storageKey = this.getStorageKey(key);
    const storedItem = window.sessionStorage.getItem(storageKey);
    return storedItem ? JSON.parse(storedItem).payload : null;
  }
  run() {
    this.checkCacheAndInvalidate();
  }
  getExpirationTime() {
    return +new Date() + this.ttl;
  }
  getStorageKey(key) {
    return this.keyPreffix + key;
  }
  getCacheKeys() {
    return Object.keys(sessionStorage).filter((key) =>
      key.includes(this.keyPreffix)
    );
  }
  checkCacheAndInvalidate() {
    const targetKeys = this.getCacheKeys();
    targetKeys.forEach((key) => {
      const item = window.sessionStorage.getItem(key);
      if (item) {
        const expire = Number(JSON.parse(item)?.expire);
        if (+new Date() > expire) {
          window.sessionStorage.removeItem(key);
        }
      }
    });
  }
  clear() {
    const targetKeys = this.getCacheKeys();
    targetKeys.forEach((key) => {
      window.sessionStorage.removeItem(key);
    });
  }
  destroy() {
    window.clearInterval(this.timer);
  }
  static getInstance(ttl, timerInterval) {
    if (!this.instance) {
      this.instance = new ClientCache(ttl, timerInterval);
    }
    return this.instance;
  }
}

export default ClientCache;
