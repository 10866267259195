import React, { useCallback, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import styles from "./index.module.scss";

const FindMe = ({ user, setItems, items }) => {
  const [mode, setMode] = useState("all");
  const itemsSnapshot = useRef([]);

  const onClickBtn = useCallback(() => {
    if (mode === "all") {
      itemsSnapshot.current = items;
      setMode("me");
      setItems(items.filter((item) => item.UserId === user?.me?.userId));
    } else {
      setItems(itemsSnapshot.current);
      setMode("all");
    }
  }, [mode, user, itemsSnapshot, setItems, items]);

  return (
    <Button onClick={onClickBtn} variant="link" className={styles.text}>
      {mode === "all" ? "Знайти себе" : "Показати всіх"}
    </Button>
  );
};

export default FindMe;
