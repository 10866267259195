import React from "react";
import AnimatedNumber from "animated-number-react";

const CustomAnimatedNumber = ({ value, delay = 100 }) => {
  const formatValue = (value) => value.toFixed(0);

  return (
    <AnimatedNumber
      duration={500}
      formatValue={formatValue}
      value={value}
      delay={delay}
    />
  );
};

export default CustomAnimatedNumber;
