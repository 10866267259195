import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import API from "../../api";

export const getLeagueInviteUrl = (createdLeagueId) => {
  const port = window.location.port === "" ? "" : `:${window.location.port}`;
  return `${window.location.protocol}//${window.location.hostname}${port}/home?invite=${createdLeagueId}`;
};

const CreatePrivateLeague = ({ show, onHide }) => {
  const [copied, setCopied] = useState(false);
  const [title, setTitle] = useState("");
  const [createdLeagueId, setCreatedLeagueId] = useState(null);
  const [loading, setLoading] = useState(false);

  const createLeague = async () => {
    try {
      setLoading(true);
      const response = await API.createLeague({
        title,
      });
      setCreatedLeagueId(response.data.LeagueId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onHideModal = () => {
    onHide(createdLeagueId);
    setTitle("");
    setCreatedLeagueId(null);
  };

  useEffect(() => {
    setCopied(false);
  }, [show]);
  return (
    <Modal show={show} size="md" backdrop="static" onHide={() => onHideModal()}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-plus-circle-fill"></i>&nbsp;Створення ліги
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <>
          <p>
            Створіть приватну лігу, запросіть до неї друзів та змагайтеся
            кожного туру разом з{" "}
            <strong>{process.env.REACT_APP_FANTASY_TITLE}</strong>.
          </p>
          <p>
            <>
              <Form.Label htmlFor="league-title">
                <strong>Назва ліги</strong>
              </Form.Label>
              <Form.Control
                style={{ fontSize: "1.3rem" }}
                disabled={createdLeagueId !== null}
                type="text"
                maxLength={35}
                onKeyUp={(e) => setTitle(e.target.value)}
                id="league-title"
                aria-describedby="titleHelpBlock"
                autoComplete={`off`}
              />
              <Form.Text id="titleHelpBlock" muted>
                Назва ліги має включати не більше 35 символів
              </Form.Text>
            </>
          </p>
        </>

        <div className={styles.actions}>
          {createdLeagueId === null ? (
            <Button
              disabled={!title.trim().length || loading}
              onClick={createLeague}
              className={styles.btn}
            >
              <i className="bi bi-check-circle-fill"></i>&nbsp;
              {loading ? "Створюємо..." : "Створити"}
            </Button>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <p>
                <Form.Label>
                  <strong>Лінк-запрошення</strong>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    style={{ fontSize: "1.3rem" }}
                    value={getLeagueInviteUrl(createdLeagueId)}
                    disabled={true}
                    aria-label="Запрошення"
                    aria-describedby="basic-addon2"
                  />
                  <CopyToClipboard
                    text={getLeagueInviteUrl(createdLeagueId)}
                    onCopy={() => setCopied(true)}
                  >
                    <Button id="button-addon2">
                      <i className="bi bi-clipboard"></i>
                    </Button>
                  </CopyToClipboard>
                </InputGroup>{" "}
              </p>

              {copied && (
                <p className={styles.hint}>
                  Лінк-запрошення скопійовано до буферу обміну, пошарте його з
                  друзями, яких ви б хотіли додати до вашої ліги.
                </p>
              )}

              <Button onClick={onHideModal} className={styles.btn}>
                <i className="bi bi-x-circle"></i>&nbsp;Закрити
              </Button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreatePrivateLeague;
