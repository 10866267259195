import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Placeholder } from "react-bootstrap";
import API from "../../api";
import RoundMatches from "../../components/RoundMatches";
import styles from "./index.module.scss";

const Calculation = ({ show, onHide }) => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newRoundProcess, setNewRoundProcess] = useState(false);

  const fetchSettings = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.fetchSettings();
      setSettings(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (show) fetchSettings();
  }, [show, fetchSettings]);

  const onHideModal = () => {
    onHide();
    setSettings(null);
  };

  const onHidePreviewModal = async (needToRefresh) => {
    if (needToRefresh) fetchSettings();
  };

  const closeRound = async () => {
    try {
      setLoading(true);

      await API.savePrevRanks();
      await API.closeRound();
      await API.generateStandings();
      fetchSettings();
    } catch (err) {
      onHideModal();
    } finally {
      setLoading(false);
    }
  };

  const newRound = async () => {
    try {
      const fromScratch = settings?.currentRoundMatches?.length === 0;
      setLoading(true);
      if (!fromScratch) {
        await API.generateStandings();
      }
      setNewRoundProcess(true);
      await API.createRound();
      fetchSettings();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const isReadyToCloseRound =
    settings?.currentRoundMatches?.filter((match) => match.Calculated)
      .length === settings?.currentRoundMatches?.length &&
    settings?.currentRoundMatches?.length > 0 &&
    !Boolean(settings?.currentRoundIsClosed);

  const isReadyToCalculateMatches = settings?.currentRoundMatches?.length > 0;
  const isReadyToOpenRound = Boolean(settings?.currentRoundIsClosed);

  return (
    <Modal show={show} size="lg" onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-calculator"></i>Калькуляція
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: 250 }}>
        {loading ? (
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          </Placeholder>
        ) : (
          isReadyToCalculateMatches && (
            <RoundMatches
              onHidePreviewModal={onHidePreviewModal}
              currentRound={settings?.currentRound}
              roundMatches={settings?.currentRoundMatches}
              isAdmin
              newRoundProcess={newRoundProcess}
              setNewRoundProcess={setNewRoundProcess}
            />
          )
        )}

        <div className={styles.actions}>
          {isReadyToOpenRound && <Button onClick={newRound}>Новий тур</Button>}

          {isReadyToCloseRound && (
            <Button variant="danger" onClick={closeRound}>
              Закрити тур
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Calculation;
