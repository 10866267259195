import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import API from "../../api";
import styles from "./index.module.scss";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import jsonReducer from "./jsonReducer";
import jsonValidate from "./jsonValidate";
import { FormCheck } from "react-bootstrap";
import Fixture from "../../components/Fixture";

const Preview = ({ show, onHide, jsonRaw, selectedRound, draftMode }) => {
  const [loading, setLoading] = useState(false);
  const [rawMode, setRawMode] = useState(true);
  const [json, dispatch] = useReducer(jsonReducer, null);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState(null);
  const editorEl = useRef(null);
  const editor = useRef(null);

  const setJson = useCallback(
    (data) => dispatch({ type: "set", payload: data }),
    [dispatch]
  );

  useEffect(() => {
    if (!json && jsonRaw) {
      setJson(jsonRaw);
    }
  }, [jsonRaw, setJson, json]);

  useEffect(() => {
    if (json === null) return;
    if (show && rawMode) {
      editor.current = new JSONEditor(editorEl.current, { mode: "code" });
      editor.current.set(json);
    }
  }, [show, json, rawMode]);

  const onHideModal = (needToRefresh) => {
    if (draftMode) {
      saveDraft();
    }
    onHide(needToRefresh);
    setJson(null);
    setIsValid(false);
    setError(null);
    setSummary(null);
    editor.current.destroy();
  };

  useEffect(() => {
    if (!rawMode) {
      editor.current.destroy();
      editorEl.current = null;
    }
  }, [rawMode]);

  const calcMatches = async () => {
    try {
      setLoading(true);
      const payload = rawMode ? editor.current.get() : json;
      await API.calcMatches(payload, selectedRound);
      await API.generateStandings();
      onHideModal(true);
      localStorage.removeItem(payload.fixture.id);
    } catch (err) {
      onHideModal();
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    try {
      const newJson = rawMode ? editor.current.get() : json;
      const { isValid, errorMsg, summary } = jsonValidate(newJson);
      setError(errorMsg);
      setIsValid(isValid);
      setSummary(summary);
    } catch (err) {
      console.log(err);
      setError("Невалідний JSON");
      setIsValid(false);
      setSummary(null);
    }
  };

  const onToggle = () => {
    if (rawMode) {
      setJson(editor.current.get());
    }
    setRawMode((prevState) => !prevState);
  };

  const saveDraft = () => {
    const payload = rawMode ? editor.current.get() : json;
    localStorage.setItem(json.fixture.id, JSON.stringify(payload));
  };

  return (
    <Modal show={show} size="lg" onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-calculator"></i>&nbsp;Прев'ю
        </Modal.Title>
        <FormCheck // prettier-ignore
          type="switch"
          id="switch-preview-mode"
          className={styles.toggle}
          onChange={(e) => onToggle(e.target.checked)}
          defaultChecked={false}
        />
      </Modal.Header>

      <Modal.Body>
        <>
          {rawMode ? (
            <div
              ref={editorEl}
              style={{ width: "100%", height: "70vh" }}
              id="json-editor"
            ></div>
          ) : (
            json && (
              <Fixture json={json} dispatch={dispatch} isAdminMode={true} />
            )
          )}

          <div className={styles.actions}>
            <div className={styles.leftPanel}>
              <Button style={{ fontSize: "1.3rem" }} onClick={validate}>
                Валідувати
              </Button>
              {error && (
                <span style={{ color: "red", marginLeft: 8 }}>{error}</span>
              )}
              {summary?.numplayers > 0 && (
                <span className={styles.summary}>
                  <span style={{ marginLeft: 8 }}>
                    <i className="bi bi-people-fill"></i>&nbsp;
                    {summary?.numplayers}
                  </span>
                  <span style={{ marginLeft: 8 }}>
                    <i className="bi bi-arrow-clockwise"></i> {summary?.subst}
                  </span>
                  <span style={{ marginLeft: 8 }}>
                    <i className="bi bi-box"></i> {summary?.yellowcards}
                  </span>
                  <span style={{ marginLeft: 8 }}>
                    <i className="bi bi-box-fill"></i> {summary?.redcards}
                  </span>
                  <span style={{ marginLeft: 8 }}>
                    <i className="bi bi-bullseye"></i> {summary?.goals}
                  </span>
                </span>
              )}
            </div>
            {isValid && (
              <Button
                style={{ fontSize: "1.3rem" }}
                disabled={loading}
                variant="danger"
                onClick={calcMatches}
              >
                {loading && (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;
                  </>
                )}
                Зберегти
              </Button>
            )}
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default Preview;
