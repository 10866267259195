import React from "react";
import Page from "../components/Page";
import BackButton from "../components/BackButton";
import { Badge, Table } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";
import useIsMobile from "../hooks/useIsMobile";
import { patreonUrl } from "../constants";

const Rules = () => {
  const isMobile = useIsMobile();

  return (
    <Page>
      <div className="page-bar">
        <div className="inner">
          <ScrollToTop />
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackButton />
            <h4 className="bar-title">Правила гри</h4>
          </div>
        </div>
      </div>
      <div className="page-offset">
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-patch-question"></i>&nbsp;Як почати грати?
        </h5>
        <p>
          ✅ Зберіть команду з 15 футболістів – 11 основних і 4 заміни. Склад
          має включати 2 голкіперів, 5 захисників, 5 півзахисників і 3
          нападники.
        </p>
        <p>
          ✅ Загальна вартість вашої команди має не перевищувати 100 умовних
          одиниць.
        </p>
        <p>✅ Ви не можете взяти більше 2 футболістів одного клубу.</p>
        <p>
          ✅ Оберіть схему гри. Вона має включати 1 голкіпера, мінімум 3
          захисники та мінімум 1 нападника. Схему можна змінювати кожного туру.
        </p>
        <p>
          ✅ Кожного туру обирайте капітана (К) та віце-капітана (ВК). Очки
          капітана подвоюються. Якщо основний капітан не зіграв, то подвояться
          очки віце-капітана.{" "}
        </p>
        <p>
          ✅ Будьте уважні - якщо ви використали усі трансфери і натиснули
          кнопку «Зберегти», більше не буде можливості купувати і продавати
          гравців до закінчення туру. Ви зможете змінити лише схему, заміни і
          капітана. Дедлайн будь-яких змін у складі – за 1,5 години до початку
          першого матчу кожного туру.
        </p>
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-patch-question"></i>&nbsp;Як грати далі?
        </h5>
        <p>
          ✅ Очки для вашої команди набирають основні гравці. Запасні виходять
          на заміну автоматично, якщо основні не зіграли. Але запасний гравець
          може замінити лише футболіста того ж амплуа.
        </p>
        <p>
          ✅ Якщо у вас кілька запасних гравців одного амплуа, ви можете
          виставити пріоритет - хто першим стане основним. Для цього зробіть
          заміну на лаві запасних - щоб амплуа пріоритетного гравця вказувалось
          з цифрою 1.
        </p>
        <p>
          ✅ Після кожного туру ви можете зробити заміни і 2 трансфери – продати
          і купити 2 гравців. Перші три тури і стартовий тур після зимової паузи
          – кількість трансферів необмежена. Невикористані трансфери
          зберігаються та можуть бути використані у наступних турах.
        </p>{" "}
        <p>
          ✅ Перенесені матчі будуть прикріплятись до туру після цього матчу.
          Наприклад, якщо перенесений матч буде зіграно між 20-м і 21-м турами,
          то він буде прикріплений до 21-го туру.
        </p>
        <p>
          ✅ Ви можете грати в FANTASY UPL безкоштовно протягом 3 тижнів з
          моменту реєстрації. Після чого потрібно{" "}
          <a href={patreonUrl} target="_blank" rel="noreferrer">
            стати патроном KDK
          </a>{" "}
          з платежем $2 або більше.
        </p>
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-patch-question"></i>&nbsp;Як нараховуються бали?
        </h5>
        <p>
          ✅ Гравці набирають очки в залежності від власних дій і результатів
          їхніх команд. Всі дані ми отримуємо від постачальника статистики і не
          можемо їх змінювати.{" "}
        </p>
        <p>
          ✅ У разі, коли одній з команд зараховується технічна перемога (іншій
          - технічна поразка), усі футболісти команди-переможця отримують очки:
          голкіпери та захисники 6 очок, півзахисники 4 очки, нападники 3 очки.
          Відповідно усі футболісти команди, що програла, отримують 0 очок. При
          цьому такі футболісти не можуть бути замінені за рахунок автозаміни.
        </p>
        {isMobile ? (
          <Table striped size="sm" style={{ fontSize: "1.3rem" }}>
            <thead>
              <tr>
                <th>Правило</th>
                <th>Бали</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Зіграв менше 60 хвилин</td>
                <td>1</td>
              </tr>
              <tr>
                <td>Зіграв більше 60 хвилин (без врахування доданого часу)</td>
                <td>2</td>
              </tr>
              <tr>
                <td>За гол голкіпера</td>
                <td>7</td>
              </tr>
              <tr>
                <td>За гол захисника</td>
                <td>6</td>
              </tr>
              <tr>
                <td>За гол півзахисника</td>
                <td>5</td>
              </tr>
              <tr>
                <td>За гол нападника</td>
                <td>4</td>
              </tr>
              <tr>
                <td>За гол голкіпера з пенальті</td>
                <td>5</td>
              </tr>
              <tr>
                <td>За гол захисника з пенальті</td>
                <td>4</td>
              </tr>
              <tr>
                <td>За гол півзахисника з пенальті</td>
                <td>3</td>
              </tr>
              <tr>
                <td>За гол нападника з пенальті</td>
                <td>2</td>
              </tr>
              <tr>
                <td>За гольовий асист голкіпера</td>
                <td>5</td>
              </tr>
              <tr>
                <td>За гольовий асист захисника</td>
                <td>4</td>
              </tr>
              <tr>
                <td>За гольовий асист півзахисника</td>
                <td>3</td>
              </tr>
              <tr>
                <td>За гольовий асист нападника</td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  За сухий матч команди голкіпера<sup>1</sup>
                </td>
                <td>5</td>
              </tr>
              <tr>
                <td>
                  За сухий матч команди захисника<sup>1</sup>
                </td>
                <td>4</td>
              </tr>
              <tr>
                <td>
                  За сухий матч команди півзахисника<sup>1</sup>
                </td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  За сухий матч команди нападника<sup>1</sup>
                </td>
                <td>1</td>
              </tr>
              <tr>
                <td>Заробив пенальті в чужі ворота</td>
                <td>1</td>
              </tr>
              <tr>
                <td>Заробив пенальті в свої ворота</td>
                <td>-1</td>
              </tr>
              <tr>
                <td>Не дозволив забити з пенальті</td>
                <td>4</td>
              </tr>
              <tr>
                <td>За нереалізований пенальті</td>
                <td>-2</td>
              </tr>
              <tr>
                <td>
                  За вольову перемогу (команда першою пропустила, але перемогла)
                  <sup>2</sup>
                </td>
                <td>1</td>
              </tr>
              <tr>
                <td>
                  За кожні забиті командою 2 голи для нападника або півзахисника
                  <sup>2</sup>
                </td>
                <td>1</td>
              </tr>
              <tr>
                <td>
                  За кожні пропущені командою 2 голи для голкіпера або захисника
                  <sup>2</sup>
                </td>
                <td>-1</td>
              </tr>
              <tr>
                <td>
                  За жовту картку<sup>3</sup>
                </td>
                <td>-1</td>
              </tr>
              <tr>
                <td>
                  За червону картку<sup>3</sup>
                </td>
                <td>-3</td>
              </tr>
              <tr>
                <td>За автогол</td>
                <td>-2</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Table
            className="rules-table"
            striped
            size="sm"
            style={{ fontSize: "1.3rem" }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Правило</th>
                <th>
                  <Badge style={{ color: "#161616" }}>ГК</Badge>
                </th>
                <th>
                  <Badge style={{ color: "#161616" }}>ЗАХ</Badge>
                </th>
                <th>
                  <Badge style={{ color: "#161616" }}>ПІВ</Badge>
                </th>
                <th>
                  <Badge style={{ color: "#161616" }}>НАП</Badge>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Зіграв більше 60 хвилин (без врахування доданого часу)</td>
                <td>2</td>
                <td>2</td>
                <td>2</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Зіграв менше 60 хвилин</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>Гол</td>
                <td>7</td>
                <td>6</td>
                <td>5</td>
                <td>4</td>
              </tr>
              <tr>
                <td>Гол з пенальті</td>
                <td>5</td>
                <td>4</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Заробив пенальті в чужі ворота</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>Заробив пенальті в свої ворота</td>
                <td>-1</td>
                <td>-1</td>
                <td>-1</td>
                <td>-1</td>
              </tr>
              <tr>
                <td>Не забив з пенальті</td>
                <td>-2</td>
                <td>-2</td>
                <td>-2</td>
                <td>-2</td>
              </tr>
              <tr>
                <td>Не дозволив забити з пенальті</td>
                <td>4</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Автогол</td>
                <td>-2</td>
                <td>-2</td>
                <td>-2</td>
                <td>-2</td>
              </tr>
              <tr>
                <td>Асист</td>
                <td>5</td>
                <td>4</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  Сухий матч команди<sup>1</sup>
                </td>
                <td>5</td>
                <td>4</td>
                <td>2</td>
                <td>1</td>
              </tr>
              <tr>
                <td>
                  Кожні 2 пропущені командою м'ячі<sup>2</sup>
                </td>
                <td>-1</td>
                <td>-1</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Кожні 2 забиті командою м'ячі<sup>2</sup>
                </td>
                <td></td>
                <td></td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>
                  Вольова перемога<sup>2</sup>
                </td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>

              <tr>
                <td>
                  Жовта картка<sup>3</sup>
                </td>
                <td>-1</td>
                <td>-1</td>
                <td>-1</td>
                <td>-1</td>
              </tr>
              <tr>
                <td>
                  Червона картка<sup>3</sup>
                </td>
                <td>-3</td>
                <td>-3</td>
                <td>-3</td>
                <td>-3</td>
              </tr>
            </tbody>
          </Table>
        )}
        <p>
          <sup>1</sup> - сухий матч зараховується футболісту, якщо він провів на
          футбольному полі мінімум 60 хвилин (без врахування доданого часу) та
          його команда не пропустила гол у матчі.
        </p>
        <p>
          <sup>2</sup> - футболіст має знаходитися на полі мінімум 60 хвилин.
        </p>
        <p>
          <sup>3</sup> - якщо футболіст отримує дві жовті та автоматичну
          червону, то рахуватиметься тільки червона картка.
        </p>
        <h5 style={{ marginBottom: 12 }}>
          <i className="bi bi-patch-question"></i>&nbsp;Що ще треба знати?{" "}
        </h5>
        <p>
          ✅ Очки вашої команди нараховуються в 4 рейтингах – загальний,
          місячний, туру і ліга вболівальників вашого клубу. Змінити улюблений
          клуб можна лише один раз протягом місяця з моменту реєстрації у
          турнірі.{" "}
        </p>
        <p>
          ✅ Ви можете створити власну приватну лігу і змагатися з тими, кому
          передасте посилання на неї.
        </p>
        <p>
          ✅ Нарахування очок та оновлення рейтингів відбувається протягом 12
          годин після завершення поточного туру змагань.
        </p>
        <p>
          ✅ Топ-10 загального рейтингу за підсумками сезону отримають річну
          підписку на{" "}
          <a
            href="https://setantasports.com/uk/?cid=faupl"
            target="_blank"
            rel="noreferrer"
          >
            Setanta Sports
          </a>
          . Такий же подарунок отримають всі переможці місячних рейтингів. А
          переможці кожного туру – місячну підписку на{" "}
          <a
            href="https://setantasports.com/uk/?cid=faupl"
            target="_blank"
            rel="noreferrer"
          >
            Setanta Sports
          </a>
          .
        </p>
        <p>
          ✅ За умови рівності очок, для визначення переможця рейтингів
          застосовуються додаткові показники. Перевагу отримують учасники, у
          яких спрацювала менша кількість автозамін. Якщо ж і цей показник
          рівний, то вище будуть учасники, які набрали більшу кількість очок без
          врахування подвоєння очок капітана. У випадку, коли додаткові
          показники не дозволяють визначити переможця, він визначатиметься за
          допомогою рандомайзера.
        </p>
        <p>
          ✅ Переможці клубних рейтингів теж отримають подарунки – футболки
          своїх улюблених команд! Як і минулого разу, погодились підтримати
          своїх вболівальників Динамо, Шахтар, Карпати, Кривбас, Верес,
          Чорноморець, Полісся, Рух, ЛНЗ, до них додалась Ворскла.
        </p>
        <p>
          ✅ Підпишіться на{" "}
          <a
            href={process.env.REACT_APP_TELEGRAM_CHANNEL_LINK}
            target="_blank"
            rel="noreferrer"
          >
            офіційний канал {process.env.REACT_APP_FANTASY_TITLE}
          </a>{" "}
          – там ми будемо нагадувати зробити зміни в складі перед стартом
          кожного туру, повідомляти про важливі новини і відповідати на ваші
          питання/зауваження.
        </p>
      </div>
    </Page>
  );
};

export default Rules;
