import React, { useState, useContext } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import FootballField from "../FootballField";
import PlayerRoundInfo from "../../modals/PlayerRoundInfo";
import { getPlayerById } from "../../helpers/player";
import { AdminContext } from "../../providers/AdminProvider";

const RoundDetailsView = ({ squad, details, round }) => {
  const [playerRoundModal, setPlayerRoundModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const { players } = useContext(AdminContext);

  const onHideModal = () => {
    setPlayerRoundModal(false);
  };

  const onPlayerClick = ({ playerId }) => {
    const player = getPlayerById(players, playerId);
    setSelectedPlayer(player);
    setPlayerRoundModal(true);
  };

  return (
    <Grid
      fluid
      style={{ paddingLeft: 0, paddingRight: 0 }}
      className="page-offset"
    >
      <Row style={{ marginBottom: 8 }}>
        <Col xs={12} md={6}>
          {squad !== null && (
            <FootballField
              squad={squad}
              onItemClick={onPlayerClick}
              isGoalkeeperClickAllowed={true}
              isDefenderClickAllowed={true}
              isMidfielderClickAllowed={true}
              isAttackerClickAllowed={true}
              action=""
              selectedItem={null}
              outPlayer={null}
              selectedDetails={details}
              isHistoricalView={true}
              isBestPlayersView={true}
            />
          )}
        </Col>
      </Row>
      <PlayerRoundInfo
        show={playerRoundModal}
        onHide={onHideModal}
        player={selectedPlayer}
        round={round}
      />
    </Grid>
  );
};

export default RoundDetailsView;
