const emptyEvent = {
  time: {
    elapsed: null,
    extra: null,
  },
  team: {
    id: null,
  },
  player: {
    id: null,
  },
  assist: {
    id: null,
  },
  type: "Goal",
  detail: "Normal Goal",
};

export default emptyEvent;
