import React from "react";
import styles from "./index.module.scss";
import Page from "../Page";
import { tgLink } from "../../constants";
import { Link } from "react-router-dom";

const LandingHeader = () => {
  return (
    <section className={styles.header}>
      <Page zeroPadding>
        <div className={styles.cnt}>
          <div
            className={`${styles.link} ${styles.visible}`}
            onClick={() => (window.location.href = tgLink)}
          >
            <i className="bi bi-headset"></i>&nbsp;Зворотний зв'язок
          </div>
          <div className={styles.link}>
            <Link to="/terms-of-service">
              <i className="bi bi-file-check"></i>&nbsp;Угода користувача
            </Link>
          </div>
          <div className={styles.link}>
            <Link to="/privacy-policy">
              <i className="bi bi-cookie"></i>&nbsp;Використання cookies
            </Link>
          </div>
        </div>
      </Page>
    </section>
  );
};

export default LandingHeader;
