import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AuthContext } from "../../providers/AuthProvider";

const Support = ({ show, onHide }) => {
  const { user } = useContext(AuthContext);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setCopied(false);
  }, [show]);
  return (
    <Modal show={show} size="md" backdrop="static" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-headset"></i>&nbsp;Техпідтримка
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <>
          <p>
            Шановні користувачі! Для того щоб ми змогли швидше вирішити вашу
            проблему натисніть кнопку "Скопіювати" та відправте нам службову
            інформацію за допомогою будь-якого безпечного каналу зв'язку.
          </p>
          <p style={{ color: "red" }}>
            Увага! Не розповсюджуйте службову інформацію вашого користувача у
            пабліках та групових чатах. Це призведе до порушення вашої
            інформаційної безпеки!
          </p>
        </>

        <div className={styles.actions}>
          <CopyToClipboard
            text={`${user?.me?.userId};${localStorage.getItem("access_token")}`}
            onCopy={() => setCopied(true)}
          >
            <Button className={styles.btn}>
              <i className="bi bi-clipboard"></i>&nbsp;Скопіювати
            </Button>
          </CopyToClipboard>
        </div>
        {copied && <p>Службова інформація скопійована до буферу обміну</p>}
      </Modal.Body>
    </Modal>
  );
};

export default Support;
