import React, { createContext, useState, useEffect, useContext } from "react";
import API from "../api";
import { AuthContext } from "./AuthProvider";
import { useLocation } from "react-router-dom";

const isShouldFetchPlayers = (newCache, pathname) => {
  const oldCache = localStorage.getItem("cache");
  return (
    pathname === "/administration" || Number(oldCache) !== Number(newCache)
  );
};

export const AdminContext = createContext({
  players: [],
  filteredPlayers: [],
  searchPlayer: null,
  searchPosition: "All",
  searchTeam: -1,
  setPlayers: () => undefined,
  setFilteredPlayers: () => undefined,
  setSearchPlayer: () => undefined,
  setSearchPosition: () => undefined,
  setSearchTeam: () => undefined,
  sortKey: "Cost",
  setSortKey: () => undefined,
});

function AdminProvider({ children }) {
  const [players, setPlayers] = useState(
    () => JSON.parse(localStorage.getItem("players")) || []
  );
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [searchPlayer, setSearchPlayer] = useState(null);
  const [searchPosition, setSearchPosition] = useState("All");
  const [searchTeam, setSearchTeam] = useState(-1);
  const [sortKey, setSortKey] = useState("Cost");

  const { pathname } = useLocation();

  const { loaded, user } = useContext(AuthContext);

  useEffect(() => {
    const fetchPlayers = async (pathname, timestamp) => {
      const response = await API.fetchPlayers();
      localStorage.setItem("cache", timestamp);
      if (pathname === "/administration") {
        setPlayers(response.data);
      } else {
        setPlayers(response.data.filter((item) => item.Active));
      }
    };

    if (
      loaded &&
      isShouldFetchPlayers(user?.cache?.timestamp, pathname) &&
      (["/administration", "/home"].includes(pathname) ||
        pathname.includes("/details"))
    )
      fetchPlayers(pathname, user?.cache?.timestamp);
    // eslint-disable-next-line
  }, [loaded, pathname, user]);

  useEffect(() => {
    localStorage.setItem("players", JSON.stringify(players));
  }, [players]);

  return (
    <AdminContext.Provider
      value={{
        players,
        setPlayers,
        filteredPlayers,
        setFilteredPlayers,
        searchPlayer,
        setSearchPlayer,
        searchPosition,
        setSearchPosition,
        searchTeam,
        setSearchTeam,
        sortKey,
        setSortKey,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export default AdminProvider;
