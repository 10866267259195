import React, { useContext, useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { AdminContext } from "../../providers/AdminProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { getPlayerById, getPlayerLastName } from "../../helpers/player";
import PlayerItem from "./player-item";
import EventInfo from "./event";
import Accordion from "react-bootstrap/Accordion";
import emptyEvent from "./emptyEvent";
import EventView from "./event-view";
import { Badge } from "react-bootstrap";
import { getTeamLogoUrl } from "../../helpers/utils";

const Fixture = ({ json = [], dispatch = () => undefined, isAdminMode }) => {
  const { lineups, teams, goals, events } = json;
  const { players } = useContext(AdminContext);

  const [renderEvents, setRenderEvents] = useState([]);
  const accElement = useRef(null);

  useEffect(() => {
    if (isAdminMode) return;
    const buttons = accElement.current.querySelectorAll("button");
    buttons.forEach((el) => el.setAttribute("disabled", true));
  }, [json, isAdminMode]);

  const homeTeamId = teams.home.id;
  const awayTeamId = teams.away.id;

  const [playersList, setPlayersList] = useState([]);
  const [fixtureTeams, setFixtureTeams] = useState([]);

  const { teams: teamList } = useContext(SettingsContext);

  useEffect(() => {
    const list = players
      .filter((player) => [awayTeamId, homeTeamId].includes(player.teamId))
      .map((player) => ({
        label:
          (player.FirstName?.charAt(0) + "." || "") +
          " " +
          getPlayerLastName(player),
        value: player.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setPlayersList([...list, { value: null, label: "-" }]);
  }, [players, homeTeamId, awayTeamId]);

  useEffect(() => {
    setFixtureTeams([
      { label: teamList[homeTeamId].name, value: homeTeamId },
      { label: teamList[awayTeamId].name, value: awayTeamId },
    ]);
  }, [teamList, homeTeamId, awayTeamId]);

  const homeSquad = lineups[0];
  const awaySquad = lineups[1];

  const homeGoals = goals.home;
  const awayGoals = goals.away;

  const getPlayer = (players, item) => {
    return item.player.id ? getPlayerById(players, item.player.id) : null;
  };

  useEffect(() => {
    const renderEvents = isAdminMode
      ? events
      : events.sort(
          (a, b) =>
            Number(a.time.elapsed) +
            Number(a.time.extra) -
            (Number(b.time.elapsed) + Number(b.time.extra))
        );
    setRenderEvents(renderEvents);
  }, [events, isAdminMode]);

  return (
    <div className={`${styles.fixture} ${isAdminMode ? styles.admin : null}`}>
      <div className={`${styles.cnt} ${styles.headRow}`}>
        <div className={styles.team}>
          <img
            style={{
              height: 30,
              objectFit: "contain",
              marginRight: 4,
              filter: "sepia(1)",
            }}
            src={getTeamLogoUrl(homeTeamId)}
            alt=""
          />
          {teamList[homeTeamId].name}
        </div>
        <Badge bg="warning" style={{ fontSize: "1.3rem" }} className="darkText">
          {homeGoals}:{awayGoals}
        </Badge>
        <div className={styles.team} style={{ textAlign: "right" }}>
          {teamList[awayTeamId].name}
          <img
            style={{
              height: 30,
              objectFit: "contain",
              marginLeft: 4,
              filter: "sepia(1)",
            }}
            src={getTeamLogoUrl(awayTeamId)}
            alt=""
          />
        </div>
      </div>
      <div className={styles.cnt}>
        <div className={styles.events}>
          <Accordion ref={accElement} alwaysOpen={false}>
            {renderEvents.map((ev, index) => (
              <Accordion.Item
                key={JSON.stringify(ev)}
                eventKey={index}
                className={!isAdminMode ? "disabled" : null}
              >
                <Accordion.Header>
                  <EventView
                    event={ev}
                    players={players}
                    fixtureTeams={fixtureTeams}
                  />
                </Accordion.Header>
                <Accordion.Body>
                  <EventInfo
                    index={index}
                    dispatch={dispatch}
                    event={ev}
                    playersList={playersList}
                    fixtureTeams={fixtureTeams}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <div>
            {isAdminMode && (
              <span
                className={styles.add}
                onClick={() => {
                  dispatch({
                    type: "addEvent",
                    payload: { eventData: emptyEvent },
                  });
                }}
              >
                <>
                  <i className="bi bi-plus-circle-fill"></i>&nbsp;Додати подію{" "}
                </>
              </span>
            )}
          </div>
        </div>
      </div>

      {isAdminMode && (
        <div className={styles.cnt} style={{ marginTop: 16 }}>
          <div className={styles.home}>
            <div className={styles.subTitle}>Основний склад</div>
            {homeSquad?.startXI.map((item, index) => (
              <PlayerItem
                key={JSON.stringify(item)}
                isHome
                player={getPlayer(players, item)}
                item={item}
                playersList={playersList}
                dispatch={dispatch}
                index={index}
                playerType="homeSquad"
                isAdminMode={isAdminMode}
              />
            ))}
            {isAdminMode && homeSquad?.startXI?.length < 11 && (
              <span
                className={styles.add}
                onClick={() => {
                  dispatch({
                    type: "addHomeSquadPlayer",
                    payload: {},
                  });
                }}
              >
                {isAdminMode && (
                  <>
                    <i className="bi bi-plus-circle-fill"></i>&nbsp;Додати
                    гравця
                  </>
                )}
              </span>
            )}
            <div className={styles.subTitle}>Запасні</div>
            {homeSquad?.substitutes.map((item, index) => (
              <PlayerItem
                key={JSON.stringify(item)}
                isHome
                player={getPlayer(players, item)}
                item={item}
                playersList={playersList}
                dispatch={dispatch}
                index={index}
                playerType="homeBench"
                isAdminMode={isAdminMode}
              />
            ))}
            {isAdminMode && (
              <span
                className={styles.add}
                onClick={() => {
                  dispatch({
                    type: "addHomeBenchPlayer",
                    payload: {},
                  });
                }}
              >
                <>
                  <i className="bi bi-plus-circle-fill"></i>&nbsp;Додати гравця
                </>
              </span>
            )}
          </div>
          <div className={styles.away}>
            <div className={styles.subTitle}>Основний склад</div>
            {awaySquad?.startXI.map((item, index) => (
              <PlayerItem
                key={JSON.stringify(item)}
                player={getPlayer(players, item)}
                item={item}
                playersList={playersList}
                dispatch={dispatch}
                index={index}
                playerType="awaySquad"
                isAdminMode={isAdminMode}
              />
            ))}
            {isAdminMode && awaySquad?.startXI?.length < 11 && (
              <span
                className={styles.add}
                onClick={() => {
                  dispatch({
                    type: "addAwaySquadPlayer",
                    payload: {},
                  });
                }}
              >
                <>
                  <i className="bi bi-plus-circle-fill"></i>&nbsp;Додати гравця
                </>
              </span>
            )}
            <div className={styles.subTitle}>Запасні</div>
            {awaySquad?.substitutes.map((item, index) => (
              <PlayerItem
                key={JSON.stringify(item)}
                player={getPlayer(players, item)}
                item={item}
                playersList={playersList}
                dispatch={dispatch}
                index={index}
                playerType="awayBench"
                isAdminMode={isAdminMode}
              />
            ))}
            <div className={styles.playerItem}>
              {isAdminMode && (
                <span
                  className={styles.add}
                  style={{ alignItems: "flex-end" }}
                  onClick={() => {
                    dispatch({
                      type: "addAwayBenchPlayer",
                      payload: {},
                    });
                  }}
                >
                  <>
                    <i className="bi bi-plus-circle-fill"></i>&nbsp;Додати
                    гравця
                  </>
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Fixture;
