import React, { useContext, useEffect, useState } from "react";
import BTable from "react-bootstrap/Table";
import { squadToList } from "../../helpers/squad";
import { SquadContext } from "../../providers/SquadProvider";

const ListView = ({ table, onClickRow, allowedPosition, disallowedTeams }) => {
  const [squadList, setSquadList] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = table;

  const { squad, teamBalance } = useContext(SquadContext);
  useEffect(() => {
    setSquadList(squadToList(squad));
  }, [squad]);

  return (
    <>
      <BTable striped hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{ fontSize: "1.3rem", textTransform: "uppercase" }}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  cursor: "pointer",
                  opacity:
                    allowedPosition === "all" ||
                    (row.original.position === allowedPosition &&
                      !squadList.includes(row.original.id) &&
                      !disallowedTeams.includes(row.original.teamId) &&
                      Number(row.original.Cost) <= Number(teamBalance))
                      ? 1
                      : 0.2,
                  pointerEvents:
                    allowedPosition !== "all" &&
                    (row.original.position !== allowedPosition ||
                      squadList.includes(row.original.id) ||
                      disallowedTeams.includes(row.original.teamId) ||
                      Number(row.original.Cost) > Number(teamBalance))
                      ? "none"
                      : "all",
                  fontSize: "1.3rem",
                  lineHeight: "1.3rem",
                }}
                onClick={() => onClickRow(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="align-middle"
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>

      <nav>
        <ul className="pagination justify-content-end">
          <li
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 16,
              fontSize: "1.3rem",
              lineHeight: "1.3rem",
            }}
          >
            Ст.&nbsp;
            <strong>
              {pageIndex + 1} з {pageOptions.length}
            </strong>{" "}
          </li>
          <li
            className={`darkText page-item page-link ${
              !canPreviousPage && "disabled"
            }`}
            onClick={() => previousPage()}
            style={{ cursor: "pointer", fontSize: "1.3rem" }}
          >
            Попередня
          </li>
          <li
            className={`darkText page-item page-link ${
              !canNextPage && "disabled"
            }`}
            onClick={() => nextPage()}
            style={{ cursor: "pointer", fontSize: "1.3rem" }}
          >
            Наступна
          </li>
        </ul>
      </nav>
    </>
  );
};

export default ListView;
