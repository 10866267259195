export const rndInt = () => Math.floor(Math.random() * 4) + 1;

export const getTeamLogoUrl = (teamId, int = 1) => {
  return process.env.REACT_APP_TEAM_LOGO_URL.replace("[NUMBER]", int).replace(
    "[TEAM_ID]",
    teamId
  );
};

export const getPlayerPhotoUrl = (player) => {
  const isPhotoEnabled = process.env.REACT_APP_PHOTO_ENABLED === "true";

  return isPhotoEnabled ? player?.photo : getTeamLogoUrl(player?.teamId);
};

export function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
