import React, { useEffect, useContext, useState } from "react";
import Page from "../components/Page";
import { AuthContext } from "../providers/AuthProvider";
import { SettingsContext } from "../providers/SettingsProvider";
import API from "../api";
import StandingsView from "../components/StandingsView";
import { Button } from "react-bootstrap";
import FavTeam from "../modals/FavTeam";
import Loader from "../components/Loader";
import BackButton from "../components/BackButton";
import ScrollToTop from "../components/ScrollToTop";
import FindMe from "../components/FindMe";
import { getTeamLogoUrl, rndInt } from "../helpers/utils";

const styles = {
  text: {
    textTransform: "uppercase",
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
};

const Leagues = () => {
  const { user } = useContext(AuthContext);
  const { teams } = useContext(SettingsContext);
  const [selectedFavTeamId, setSelectedFavTeamId] = useState(null);
  const [standingItems, setStandingItems] = useState([]);
  const [selectLeagueModal, setSelectLeagueModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchLeague = async (selectedFavTeamId) => {
    try {
      setLoading(true);
      const res = await API.fetchLeague({ leagueId: selectedFavTeamId });
      setStandingItems(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sessionLeagueId = Number(window.sessionStorage.getItem("leagueId"));
    const favTeamId = sessionLeagueId
      ? sessionLeagueId
      : user?.me?.favTeamId || 550;
    setSelectedFavTeamId(favTeamId);
  }, [user]);

  useEffect(() => {
    if (!selectedFavTeamId) return;
    fetchLeague(selectedFavTeamId);
  }, [selectedFavTeamId]);

  const showModal = () => {
    setSelectLeagueModal(true);
  };

  const hideModal = () => {
    setSelectLeagueModal(false);
  };

  const onSelectTeam = (option) => {
    setSelectedFavTeamId(option.value);
    window.sessionStorage.setItem("leagueId", option.value);
    hideModal();
  };

  const hasMe =
    standingItems.filter((item) => item.UserId === user?.me?.userId)?.length >
    0;

  return (
    <Page>
      <ScrollToTop />
      <div className="page-bar">
        {" "}
        <div className="inner">
          <div>
            <BackButton />
            {selectedFavTeamId && (
              <Button
                size="sm"
                variant="warning"
                style={{
                  ...styles.text,
                  marginLeft: 8,
                  display: "inline-flex",
                  alignItems: "center",
                }}
                onClick={showModal}
              >
                <img
                  style={{
                    height: 30,
                    objectFit: "contain",
                    filter: "sepia(1)",
                  }}
                  src={getTeamLogoUrl(selectedFavTeamId, rndInt())}
                  alt=""
                />
                &nbsp;Ліга&nbsp;
                <i className="bi bi-caret-down-fill"></i>
              </Button>
            )}{" "}
            {teams[selectedFavTeamId]?.gift && (
              <i
                style={{ marginLeft: 8 }}
                className={`bi bi-gift ${styles.gift}`}
              ></i>
            )}
          </div>

          {hasMe && (
            <FindMe
              user={user}
              items={standingItems}
              setItems={setStandingItems}
            />
          )}
        </div>
      </div>
      {loading ? <Loader /> : <StandingsView items={standingItems} />}
      <FavTeam
        show={selectLeagueModal}
        onHide={hideModal}
        selectMode={true}
        onSelect={onSelectTeam}
        defaultTeamId={selectedFavTeamId}
      />
    </Page>
  );
};

export default Leagues;
