import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { goToLogin } from "../helpers/auth";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import LandingHeader from "../components/LandingHeader";
import HeroNew from "../components/HeroNew";
import { authMethods } from "../configs/authMethods";
import { useAuth0 } from "@auth0/auth0-react";

const styles = {
  loginIcon: {
    height: 39,
    fontSize: 39,
    position: "relative",
    top: -8,
  },
};

const getAuthTypeIcon = (authType) => {
  switch (authType) {
    case "patreon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 436 476"
          class="mr-2.5 w-8 fill-white cursor-pointer hover:fill-gold"
          className="tile-icon"
        >
          <title>Patreon logo</title>
          <path
            data-fill="1"
            d="M436 143c-.084-60.778-47.57-110.591-103.285-128.565C263.528-7.884 172.279-4.649 106.214 26.424 26.142 64.089.988 146.596.051 228.883c-.77 67.653 6.004 245.841 106.83 247.11 74.917.948 86.072-95.279 120.737-141.623 24.662-32.972 56.417-42.285 95.507-51.929C390.309 265.865 436.097 213.011 436 143Z"
          ></path>
        </svg>
      );
    case "auth0":
      return <i style={styles.loginIcon} className="bi bi-door-open-fill"></i>;
    default:
      return null;
  }
};

const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const { loginWithRedirect } = useAuth0();

  const onHide = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const { search } = window.location;
    if (!search && !localStorage.getItem("access-token")) {
      setShowModal(true);
    }
  }, []);
  return !showModal ? (
    <Loader text="Авторизуємо..." />
  ) : (
    <>
      <HeroNew />
      <LandingHeader />
      <Modal
        show={showModal}
        size="md"
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="flex">
            <i className="bi bi-door-open"></i>&nbsp;Вхід
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 12 }}>
          <p>
            Натискаючи кнопку я погоджуюсь з{" "}
            <Link to="/terms-of-service">користувальницькою угодою</Link> та
            приймаю{" "}
            <Link to="/privacy-policy">політики використання cookies</Link>{" "}
            сайту
          </p>
          <ul className="login-tiles">
            {authMethods.map(({ type, label }) => (
              <li
                key={type}
                className="tile"
                onClick={() => {
                  localStorage.setItem("authType", type);
                  goToLogin(type, loginWithRedirect);
                }}
              >
                {getAuthTypeIcon(type)}
                <div style={{ maxWidth: 136, fontSize: "1.3rem" }}>{label}</div>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>{" "}
    </>
  );
};

export default Login;
