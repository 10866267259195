import { useEffect, useRef } from "react";

function Component() {
  const legalContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "CookieDeclaration";
    script.src = `https://consent.cookiebot.com/${process.env.REACT_APP_COOKIE_BOT_ID}/cd.js`;
    script.type = "text/javascript";
    script.async = true;

    const legalCntCurrent = legalContainerRef.current;
    legalCntCurrent.appendChild(script);

    return () => {
      legalCntCurrent.removeChild(script);
    };
  }, []);

  return <div id="CookieDeclaration" ref={legalContainerRef} />;
}

export default Component;
