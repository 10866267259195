import React, { useState, useEffect, useContext, useCallback } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Page from "../components/Page";
import ListViewContainer from "../components/ListViewContainer";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { getMainLeagueTeams } from "../constants";
import { AuthContext } from "../providers/AuthProvider";
import { SettingsContext } from "../providers/SettingsProvider";
import { isAdmin } from "../helpers/user";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { AdminContext } from "../providers/AdminProvider";
import { Button } from "react-bootstrap";
import Calculation from "../modals/Calculation";
import API from "../api";
import { getTeamLogoUrl, rndInt } from "../helpers/utils";
import { FormCheck } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Administration = () => {
  const [isLoadingPlayers, setIsLoadingPlayers] = useState(true);
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [calcModal, setCalcModal] = useState(false);
  const [standingsRefreshing, setStandingsRefreshing] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    players,
    setPlayers,
    filteredPlayers,
    setFilteredPlayers,
    searchPlayer,
    setSearchPlayer,
    searchPosition,
    setSearchPosition,
    searchTeam,
    setSearchTeam,
  } = useContext(AdminContext);

  const {
    teams,
    maintenanceMode,
    setMaintenanceMode,
    setCurrentRound,
    setActiveDeadline,
    setRoundMatches,
    setRoundDeadline,
    setTeams,
    setMonthIndex,
  } = useContext(SettingsContext);

  useEffect(() => {
    if (players.length !== 0) setIsLoadingPlayers(false);
  }, [players]);

  useEffect(() => {
    if (!user?.me) return;

    if (!isAdmin(user)) {
      navigate("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchSettings = useCallback(async () => {
    try {
      setIsLoadingSettings(true);
      const res = await API.fetchSettings();
      setCurrentRound(res.data.currentRound);
      setActiveDeadline(res.data.isActiveDeadline);
      setRoundMatches(res.data.currentRoundMatches);
      setRoundDeadline(res.data.roundDeadline);
      setTeams(res.data.teams);
      setMonthIndex(res.data.monthIndex);
      setMaintenanceMode(res.data.maintenanceMode);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingSettings(false);
    }
  }, [
    setIsLoadingSettings,
    setCurrentRound,
    setActiveDeadline,
    setRoundMatches,
    setTeams,
    setMonthIndex,
    setRoundDeadline,
    setMaintenanceMode,
  ]);

  useEffect(() => {
    if (Object.keys(teams).length > 0) return;
    fetchSettings();
  }, [fetchSettings, teams]);

  useEffect(() => {
    const filteredPlayers = players
      .filter((item) =>
        searchPlayer
          ? item?.LastName?.includes(searchPlayer) ??
            item?.name?.includes(searchPlayer)
          : true
      )
      .filter((item) =>
        searchPosition !== "All" ? item.position === searchPosition : true
      )
      .filter((item) =>
        searchTeam !== -1 ? item.teamId === searchTeam : true
      );
    setFilteredPlayers(filteredPlayers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPlayer, searchPosition, searchTeam, players]);

  const onChangeSearchPlayer = (e) => {
    const searchKey = e.target.value;
    if (!searchKey) setSearchPlayer(null);
    if (searchKey.length < 3) return;
    setSearchPlayer(searchKey);
  };

  const onChangePosition = (position) => {
    setSearchPosition(position);
  };

  const onChangeSearchTeam = (teamId) => {
    setSearchTeam(+teamId);
  };

  const columns = React.useMemo(
    () => [
      {
        id: "position",
        accessor: "position",
        size: 40,
      },
      {
        id: "photo",
        Cell: (tableProps) => (
          <img
            style={{ borderRadius: "100%", height: 50, objectFit: "contain" }}
            src={tableProps.row.original.photo}
            width={50}
            alt=""
          />
        ),
        size: 40,
      },
      {
        Header: "Футболіст",
        accessor: "name",
      },
      {
        Header: "Клуб",
        Cell: (tableProps) => (
          <div className="logo">
            <img
              style={{
                borderRadius: "100%",
                height: 30,
                objectFit: "contain",
              }}
              className="lightText"
              src={getTeamLogoUrl(tableProps.row.original.teamId, rndInt())}
              width={30}
              alt=""
            />
          </div>
        ),
      },
      {
        Header: "Ціна",
        accessor: "Cost",
      },
      {
        Header: "ID",
        Cell: (tableProps) => (
          <CopyToClipboard text={tableProps.row.original.id}>
            <Button
              style={{ fontSize: "1.3rem", width: 110 }}
              onClick={(e) => e.stopPropagation()}
            >
              <i className="bi bi-clipboard"></i>&nbsp;
              {tableProps.row.original.id}
            </Button>
          </CopyToClipboard>
        ),
      },
    ],
    []
  );

  const showCalcModal = () => {
    setCalcModal(true);
  };

  const onHideCalcModal = () => {
    setCalcModal(false);
  };

  const refreshStandings = async () => {
    try {
      setStandingsRefreshing(true);
      await API.generateStandings();
    } catch (err) {
      console.log(err);
    } finally {
      setStandingsRefreshing(false);
    }
  };

  const onToggle = async (checked) => {
    try {
      await API.saveSettings({
        maintenanceMode: Number(checked),
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Page>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Button
              onClick={showCalcModal}
              style={{ margin: "0 0 8px", fontSize: "1.3rem" }}
            >
              <i className="bi bi-calculator"></i>&nbsp;Калькуляція
            </Button>
            <Button
              disabled={standingsRefreshing}
              onClick={refreshStandings}
              style={{ margin: "0 8px 8px", fontSize: "1.3rem" }}
            >
              <i className="bi bi-arrow-clockwise"></i>&nbsp;Оновити заліки
            </Button>
          </div>
          <div>
            {maintenanceMode !== null && (
              <FormCheck
                type="switch"
                onChange={(e) => onToggle(!e.target.checked)}
                defaultChecked={!Boolean(maintenanceMode)}
              />
            )}
          </div>
        </div>
        <Grid fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Row style={{ marginBottom: 8 }}>
            <Col
              xs={12}
              md={9}
              style={{ display: "flex", alignItems: "center" }}
            >
              <ButtonGroup>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={searchPosition}
                  variant="success"
                >
                  <Dropdown.Item onClick={() => onChangePosition("All")}>
                    Усі
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onChangePosition("Goalkeeper")}
                    active={searchPosition === "Goalkeeper"}
                  >
                    Голкіпери
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onChangePosition("Defender")}
                    active={searchPosition === "Defender"}
                  >
                    Захисники
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onChangePosition("Midfielder")}
                    active={searchPosition === "Midfielder"}
                  >
                    Півзахисники
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onChangePosition("Attacker")}
                    active={searchPosition === "Attacker"}
                  >
                    Нападаючі
                  </Dropdown.Item>
                </DropdownButton>

                <DropdownButton
                  title={teams[searchTeam]?.name || "Команда"}
                  style={{ marginLeft: 8 }}
                  variant="success"
                >
                  <Dropdown.Item onClick={() => onChangeSearchTeam(-1)}>
                    Усі
                  </Dropdown.Item>
                  {getMainLeagueTeams(teams)?.map((item) => (
                    <Dropdown.Item
                      key={item}
                      active={searchTeam === +item}
                      onClick={() => onChangeSearchTeam(item)}
                    >
                      {teams[item].name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </ButtonGroup>
              <Form.Control
                style={{ marginLeft: 8, fontSize: "1.3rem" }}
                type="text"
                placeholder="Пошук гравця"
                onChange={onChangeSearchPlayer}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {isLoadingPlayers || isLoadingSettings ? (
                <Loader />
              ) : (
                columns?.length > 0 && (
                  <ListViewContainer
                    data={filteredPlayers}
                    columns={columns}
                    players={players}
                    setPlayers={setPlayers}
                    setFilteredPlayers={setFilteredPlayers}
                    isAdmin={true}
                  />
                )
              )}
            </Col>
          </Row>
        </Grid>
        <Calculation show={calcModal} onHide={onHideCalcModal} />
      </Page>
    </>
  );
};

export default Administration;
