import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";
import { Button } from "react-bootstrap";
import API from "../../api";
import { AuthContext } from "../../providers/AuthProvider";

const isAlreadyLeagueMember = (members = [], user = {}) => {
  return members.filter((memberId) => memberId === user?.me?.userId).length > 0;
};

const InvitePrivateLeague = ({ show, onHide, leagueId }) => {
  const [league, setLeague] = useState(null);
  const [loading, setLoading] = useState(true);
  const [joined, setJoined] = useState(false);

  const { user } = useContext(AuthContext);

  const alreadyLeagueMember = isAlreadyLeagueMember(
    league !== null ? JSON.parse(league.Members) : [],
    user
  );
  const buttonText = loading
    ? "Oбробляємо..."
    : joined || alreadyLeagueMember
    ? "Зрозуміло"
    : "Приєднатися";

  const onHideModal = () => {
    onHide();
    setLeague(null);
  };

  const fetchPrivateLeagueById = async (leagueId) => {
    try {
      const response = await API.fetchPrivateLeagues(leagueId);
      setLeague(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user?.me || joined || alreadyLeagueMember) return;
    fetchPrivateLeagueById(leagueId);
  }, [leagueId, user, joined, alreadyLeagueMember]);

  const joinToLeague = async () => {
    try {
      if (joined || alreadyLeagueMember) {
        onHide();
        return;
      }

      setLoading(true);
      await API.updatePrivateLeague({
        leagueId,
      });
      setJoined(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Modal show={show} size="md" backdrop="static" onHide={() => onHideModal()}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className={`bi bi-${joined ? "check-circle" : "envelope"}`}></i>
          &nbsp; {joined ? "Вітаємо!" : "Запрошення до ліги"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {joined ? (
            <>
              <p>Ви успішно приєдналися до ліги!</p>
              <p>
                Для того щоб стежити за лігами, клікайте на іконку юзера{" "}
                <i className="bi bi-person-circle"></i> у правому верхньому куті
                екрану, обирайте пункт <i className="bi bi-incognito"></i>{" "}
                <strong>"Приватні ліги"</strong> та переглядайте турнірні
                таблиці.
              </p>
            </>
          ) : isAlreadyLeagueMember(
              league !== null ? JSON.parse(league.Members) : [],
              user
            ) ? (
            <p>
              Ви вже є учасником приватної ліги{" "}
              <strong>{league?.LeagueTitle}</strong>
            </p>
          ) : (
            <p>
              Бажаєте долучитися до приватної ліги{" "}
              <strong>{league?.LeagueTitle}</strong>, яку створив учасник{" "}
              <strong>{league?.OwnerName}</strong>?
            </p>
          )}
          <div className={styles.actions}>
            <Button disabled={loading} onClick={joinToLeague}>
              <i className="bi bi-check-circle-fill"></i>&nbsp;{buttonText}
            </Button>
          </div>{" "}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InvitePrivateLeague;
