import React, { useContext } from "react";
import HeroNew from "../components/HeroNew";
import LandingHeader from "../components/LandingHeader";
import { AuthContext } from "../providers/AuthProvider";
import Loader from "../components/Loader";

const Index = () => {
  const { loading } = useContext(AuthContext);
  return loading ? (
    <Loader text="Перевірка користувача..." />
  ) : (
    <>
      <LandingHeader />
      <HeroNew />
    </>
  );
};

export default Index;
