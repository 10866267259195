import React from "react";
import Page from "../components/Page";
import ScrollToTop from "../components/ScrollToTop";
import { tgLink } from "../constants";
import { Button } from "react-bootstrap";

const Maintenance = () => {
  return (
    <Page>
      <ScrollToTop />
      <h4 style={{ margin: "12px 0" }}>
        <i className="bi bi-gear"></i>&nbsp;Технічна пауза
      </h4>
      <img
        src="/maintenance.jpeg"
        style={{ width: "100%", height: 400, objectFit: "cover" }}
        alt="Poster"
      />
      <p style={{ marginTop: 12 }}>
        Шановні користувачі!
        <br />
        Застосунок тимчасово недоступний. Слідкуйте за оголошеннями у нашому
        офіційному каналі.
      </p>
      <Button
        style={{ fontSize: "1.3rem" }}
        onClick={() => (window.location.href = tgLink)}
      >
        <i className="bi bi-headset"></i>&nbsp;Перейти до каналу
      </Button>
    </Page>
  );
};

export default Maintenance;
