import React, { useContext, useState } from "react";
import { Modal, Badge, Placeholder } from "react-bootstrap";
import {
  getPlayerShortTeam,
  getShortPosition,
  isThick,
  getRivalTeamIdByMatchId,
} from "../../helpers/player";
import { getPlayerPhotoUrl, getTeamLogoUrl, rndInt } from "../../helpers/utils";
import styles from "./index.module.scss";
import API from "../../api";
import { useQuery } from "react-query";
import { getRuleText } from "../../helpers/squad";
import { SettingsContext } from "../../providers/SettingsProvider";
import CustomAnimatedNumber from "../../components/CustomAnimatedNumber";

const PlayerRoundInfo = ({ show, onHide, player, round }) => {
  const [playerStat, setPlayerStat] = useState(null);
  const { teams } = useContext(SettingsContext);

  useQuery(
    "query-round-player-stats",
    async () => {
      return await API.fetchPlayerStats(player.PlayerId, round);
    },
    {
      enabled: show === true,
      staleTime: 1,
      onSuccess: (res) => {
        setPlayerStat(
          res.data.map((Item) => ({
            ...Item,
            Details: JSON.parse(Item.Details),
          }))[0]
        );
      },
      onError: (err) => {
        console.log(err.response?.data || err);
      },
    }
  );

  const isDataAvailable = playerStat !== null;

  const hideModal = () => {
    onHide();
    setPlayerStat(null);
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={hideModal}
      backdrop={true}
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styles.photoCnt}>
            <img
              className={styles.photo}
              src={getPlayerPhotoUrl(player)}
              alt=""
            />
            <div className={styles.info}>
              <div className={styles.name}>
                <div>{player?.FirstName}</div>
                <div>{player?.LastName}</div>
              </div>
              <div className={styles.hint}>
                {getPlayerShortTeam(teams, player)} |{" "}
                {getShortPosition(player?.position)}
              </div>
            </div>
            {isDataAvailable && (
              <Badge className={styles.pts}>
                {<CustomAnimatedNumber value={playerStat?.Pts || "0"} />}
              </Badge>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: 300 }}>
        {!isDataAvailable && (
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} style={{ height: 30 }} />
            <Placeholder xs={12} style={{ height: 30, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 30, marginTop: 8 }} />
            <Placeholder xs={12} style={{ height: 30, marginTop: 8 }} />
          </Placeholder>
        )}

        {playerStat?.Details?.length > 0 ? (
          <div className={styles.detailsContainer}>
            <div className={styles.detailsItem}>
              <div className={styles.key} style={{ fontWeight: "bold" }}>
                ТУР {round}
              </div>
              <div className={styles.value}>Бали</div>
            </div>
            {playerStat?.Details?.map((item, index, items) => (
              <div
                key={item.PlayerId}
                className={`${styles.detailsItem} ${
                  isThick(item.id, items, index) ? styles.thick : ""
                }`}
              >
                <div className={styles.key}>
                  {" "}
                  <img
                    className={styles.rivalLogo}
                    src={getTeamLogoUrl(
                      getRivalTeamIdByMatchId(
                        item.id,
                        items,
                        playerStat.TeamRivalIds,
                        index
                      ),
                      rndInt()
                    )}
                    alt=""
                  />
                  {getRuleText(item.key)}
                </div>
                <div className={styles.value}> {item.value} </div>
              </div>
            ))}
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default PlayerRoundInfo;
