import {
  GOALKEEPER,
  DEFENDER,
  MIDFIELDER,
  ATTACKER,
  ALL_DEFENDERS,
  ALL_MIDFIELDERS,
  ALL_ATTACKERS,
} from "../constants";
import { listToSquad, squadToList } from "./squad";

export const parseScheme = (squad) => {
  const {
    main: { D, MD, ST },
  } = squad;
  const schemeArray = [D.length, MD.length, ST.length];
  return schemeArray.join("-");
};

export const getSchemePositionsMap = (scheme) => {
  let [numDef, numMid, numAtt] = scheme.split("-").map((str) => Number(str));
  let leftDef = ALL_DEFENDERS;
  let leftMid = ALL_MIDFIELDERS;
  let leftAtt = ALL_ATTACKERS;

  const defBorder = numDef;
  const mdBorder = numDef + numMid;
  const attBorder = numDef + numMid + numAtt;

  const positions = [];
  for (let i = 0; i < 15; i++) {
    if (i === 0 || i === 11) {
      positions.push(GOALKEEPER);
    } else if (i <= defBorder) {
      leftDef--;
      positions.push(DEFENDER);
    } else if (i > defBorder && i <= mdBorder) {
      leftMid--;
      positions.push(MIDFIELDER);
    } else if (i > mdBorder && i <= attBorder) {
      leftAtt--;
      positions.push(ATTACKER);
    } else if (leftDef > 0) {
      leftDef--;
      positions.push(DEFENDER);
    } else if (leftMid > 0) {
      leftMid--;
      positions.push(MIDFIELDER);
    } else if (leftAtt > 0) {
      leftAtt--;
      positions.push(ATTACKER);
    }
  }
  return positions;
};

export const getSquadAccordingToNewScheme = (scheme, squad, players) => {
  const positionsMap = getSchemePositionsMap(scheme);

  const list = positionsMap.map((position) => ({
    id: null,
    position,
  }));

  const goalkeepers = squadToList(squad)
    .filter(
      (playerId) =>
        players.find((player) => player.id === playerId)?.position ===
        GOALKEEPER
    )
    .map((playerId) => ({
      id: playerId,
      isCap: squad.cap !== null && playerId === squad.cap,
      isViceCap: squad.vcap !== null && playerId === squad.vcap,
    }))
    .sort((a, b) => b.isCap - a.isCap || b.isViceCap - a.isViceCap);

  const defenders = squadToList(squad)
    .filter(
      (playerId) =>
        players.find((player) => player.id === playerId)?.position === DEFENDER
    )
    .map((playerId) => ({
      id: playerId,
      isCap: squad.cap !== null && playerId === squad.cap,
      isViceCap: squad.vcap !== null && playerId === squad.vcap,
    }))
    .sort((a, b) => b.isCap - a.isCap || b.isViceCap - a.isViceCap);

  const midfielders = squadToList(squad)
    .filter(
      (playerId) =>
        players.find((player) => player.id === playerId)?.position ===
        MIDFIELDER
    )
    .map((playerId) => ({
      id: playerId,
      isCap: squad.cap !== null && playerId === squad.cap,
      isViceCap: squad.vcap !== null && playerId === squad.vcap,
    }))
    .sort((a, b) => b.isCap - a.isCap || b.isViceCap - a.isViceCap);

  const attackers = squadToList(squad)
    .filter(
      (playerId) =>
        players.find((player) => player.id === playerId)?.position === ATTACKER
    )
    .map((playerId) => ({
      id: playerId,
      isCap: squad.cap !== null && playerId === squad.cap,
      isViceCap: squad.vcap !== null && playerId === squad.vcap,
    }))
    .sort((a, b) => b.isCap - a.isCap || b.isViceCap - a.isViceCap);

  const newList = list
    .map((listItem) => {
      if (listItem.position === GOALKEEPER) {
        return {
          ...listItem,
          id: goalkeepers.length > 0 ? goalkeepers.shift().id : null,
        };
      }

      if (listItem.position === DEFENDER) {
        return {
          ...listItem,
          id: defenders.length > 0 ? defenders.shift().id : null,
        };
      }

      if (listItem.position === MIDFIELDER) {
        return {
          ...listItem,
          id: midfielders.length > 0 ? midfielders.shift().id : null,
        };
      }

      if (listItem.position === ATTACKER) {
        return {
          ...listItem,
          id: attackers.length > 0 ? attackers.shift().id : null,
        };
      }

      return listItem;
    })
    .map((listItem, index) => ({
      ...listItem,
      isCap: index < 11 && squad.cap !== null && squad.cap === listItem.id,
      isViceCap:
        index < 11 && squad.vcap !== null && squad.vcap === listItem.id,
    }));

  return listToSquad(newList);
};
