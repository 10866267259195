import React from "react";
import { Modal } from "react-bootstrap";
import Fixture from "../../components/Fixture";

const FixtureView = ({ show, onHide, json }) => {
  const onHideModal = () => {
    onHide();
  };

  return (
    <Modal show={show} size="lg" onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-calendar-check"></i>&nbsp;Звіт матчу
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{json && <Fixture json={json} />}</Modal.Body>
    </Modal>
  );
};

export default FixtureView;
