import React, { useContext, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Badge } from "react-bootstrap";
import { Row, Col } from "react-flexbox-grid";
import { SquadContext } from "../../providers/SquadProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { getCurrentRoundPts } from "../../helpers/squad";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { useNavigate } from "react-router-dom";
import { getAllowedTransfers, getMonthLabel } from "../../helpers/user";
import "dayjs/locale/uk";
import CustomAnimatedNumber from "../CustomAnimatedNumber";
import Countdown from "react-countdown";

dayjs.extend(isToday);
dayjs.locale("uk");

const InfoPanel = () => {
  const [allowedTransfersText, setAllowedTransfersText] = useState("");

  const {
    squad,
    squadSnapshot,
    teamBalance,
    allowedTransfers,
    squadScores,
  } = useContext(SquadContext);

  const {
    activeDeadline,
    roundDeadline,
    monthIndex,
    currentRound,
    mainInfo,
    monthInfo,
    prevRoundInfo,
    leagueInfo,
    prevMonth,
    prevLeague,
    prevMain,
  } = useContext(SettingsContext);

  const navigate = useNavigate();

  useEffect(() => {
    const allowedTransfersText = getAllowedTransfers(allowedTransfers);
    setAllowedTransfersText(allowedTransfersText);
  }, [allowedTransfers, squad, squadSnapshot]);

  const currentRoundPts = getCurrentRoundPts(squadScores);

  const goToStandings = () => {
    navigate("/standings/-1");
  };

  const monthDiff = prevMonth?.Rank - monthInfo?.Rank;
  const leagueDiff = prevLeague?.Rank - leagueInfo?.Rank;
  const mainDiff = prevMain?.Rank - mainInfo?.Rank;

  const isTodayDeadline = dayjs(new Date(roundDeadline)).isToday();
  const deadlineCaption = isTodayDeadline ? "До старту туру" : "Дедлайн туру";
  const isSeasonFinished =
    Number(process.env.REACT_APP_LAST_ROUND) < currentRound;

  const isFirstRound = currentRound === 1;

  return (
    <Row>
      <Col md={6} xs={12}>
        <div className={styles.panel}>
          <div className={styles.col}>
            <span
              className={styles.caption}
              style={{ visibility: isSeasonFinished ? "hidden" : "visible" }}
            >
              {activeDeadline ? deadlineCaption : "Поточний тур"}
            </span>
            <Badge
              onClick={() =>
                !activeDeadline &&
                !isSeasonFinished &&
                navigate(`/standings/${currentRound}`)
              }
              className={`${styles.liveBadge} ${styles.badge} ${
                !activeDeadline && !isSeasonFinished ? styles.hoverable : null
              }`}
              bg={activeDeadline && !isTodayDeadline ? "success" : "danger"}
            >
              {activeDeadline ? (
                isTodayDeadline ? (
                  <Countdown
                    daysInHours={true}
                    date={new Date(roundDeadline)}
                    onComplete={() => window.location.reload()}
                  />
                ) : (
                  dayjs(roundDeadline).format("DD MMM HH:mm")
                )
              ) : isSeasonFinished ? (
                <> Дякуємо за гру! </>
              ) : (
                <>
                  <div>LIVE</div>
                  <div className={styles.points}>
                    <CustomAnimatedNumber value={currentRoundPts} />
                  </div>
                </>
              )}
            </Badge>
          </div>

          <div className={styles.col}>
            <span className={styles.caption}>Баланс/Трансфери</span>
            <Badge
              className={`${styles.badge} ${styles.transfersBadge}`}
              bg="success"
            >
              <div>
                {teamBalance?.toFixed(1)}&nbsp;
                <i
                  style={{ fontSize: 16, position: "absolute", top: 5 }}
                  className="bi bi-coin"
                ></i>
              </div>
              <div className={styles.points}>{allowedTransfersText}</div>
            </Badge>
          </div>
          <div
            className={styles.col}
            style={{
              marginRight: 0,
            }}
          >
            <span className={styles.caption}>Минулий тур</span>
            <Badge
              style={{
                filter: isFirstRound ? "grayscale(1)" : "none",
                pointerEvents: isFirstRound ? "none" : "all",
              }}
              className={`${styles.lightTextBadge} ${styles.badge} ${styles.hoverable} ${styles.left}`}
              bg="success"
              onClick={() => navigate(`/standings/${currentRound - 1}`)}
            >
              {prevRoundInfo?.Rank || "-"}/{prevRoundInfo?.All || "-"}
              <div className={styles.points}>
                {prevRoundInfo?.Points || "-"}
              </div>
            </Badge>
          </div>
          <div className={styles.divider}></div>
        </div>
      </Col>
      <Col md={6} xs={12}>
        <div
          className={styles.panel}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <div className={styles.col}>
            <div className={styles.labelCnt}>
              <span className={styles.caption}>
                {getMonthLabel(monthIndex) || "Mісяць"}
              </span>
              {prevMonth && monthDiff !== 0 && !Number.isNaN(monthDiff) && (
                <div
                  className={styles.diff}
                  style={{ color: monthDiff > 0 ? "green" : "red" }}
                >
                  <i
                    className={`bi bi-caret-${
                      monthDiff > 0 ? "up" : "down"
                    }-fill`}
                  ></i>
                  <span>{monthDiff}</span>
                </div>
              )}
            </div>
            <Badge
              style={{
                filter: isFirstRound ? "grayscale(1)" : "none",
                pointerEvents: isFirstRound ? "none" : "all",
              }}
              className={`${styles.lightTextBadge} ${styles.badge} ${styles.hoverable} ${styles.left}`}
              bg="success"
              onClick={() => navigate(`/standings/${monthIndex}`)}
            >
              {monthInfo?.Rank || "-"}/{monthInfo?.All || "-"}
              <div className={styles.points}>{monthInfo?.Points || "-"}</div>
            </Badge>
          </div>

          <div className={styles.col}>
            <div className={styles.labelCnt}>
              <span className={styles.caption}>Ліга</span>
              {prevLeague && leagueDiff !== 0 && !Number.isNaN(leagueDiff) && (
                <div
                  className={styles.diff}
                  style={{ color: leagueDiff > 0 ? "green" : "red" }}
                >
                  <i
                    className={`bi bi-caret-${
                      leagueDiff > 0 ? "up" : "down"
                    }-fill`}
                  ></i>
                  <span>{leagueDiff}</span>
                </div>
              )}
            </div>
            <Badge
              className={`${styles.lightTextBadge} ${styles.badge} ${styles.hoverable} ${styles.left}`}
              bg="success"
              onClick={() => navigate("/leagues")}
            >
              {leagueInfo?.Rank || "-"}/{leagueInfo?.All || "-"}
              <div className={styles.points}>{leagueInfo?.Points || "-"}</div>
            </Badge>
          </div>

          <div className={styles.col} style={{ marginRight: 0 }}>
            <div className={styles.labelCnt}>
              <span className={styles.caption}>Сезон</span>
              {prevMain && mainDiff !== 0 && !Number.isNaN(mainDiff) && (
                <div
                  className={styles.diff}
                  style={{ color: mainDiff > 0 ? "green" : "red" }}
                >
                  <i
                    className={`bi bi-caret-${
                      mainDiff > 0 ? "up" : "down"
                    }-fill`}
                  ></i>
                  <span>{mainDiff}</span>
                </div>
              )}
            </div>
            <Badge
              className={`${styles.lightTextBadge} ${styles.badge} ${styles.hoverable} ${styles.left}`}
              bg="success"
              onClick={goToStandings}
            >
              {mainInfo?.Rank || "-"}/{mainInfo?.All || "-"}
              <div className={styles.points}>{mainInfo?.Points || "-"}</div>
            </Badge>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default InfoPanel;
