import React, { useContext, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Grid, Row, Col } from "react-flexbox-grid";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

import { getMainLeagueTeams } from "../../constants";
import styles from "./index.module.scss";
import ListViewContainer from "../../components/ListViewContainer";

import { AdminContext } from "../../providers/AdminProvider";
import { SquadContext } from "../../providers/SquadProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { getShortPosition, getPosition } from "../../helpers/player";
import { Modal } from "react-bootstrap";
import { getDisallowedTeamIds } from "../../helpers/squad";
import { getPlayerName } from "../../helpers/user";

const PlayerSearch = ({ show, onHide, onShowPlayerInfo }) => {
  const textInput = useRef(null);

  const {
    players,
    setPlayers,
    filteredPlayers,
    setFilteredPlayers,
    searchPlayer,
    setSearchPlayer,
    searchPosition,
    setSearchPosition,
    searchTeam,
    setSearchTeam,
    sortKey,
    setSortKey,
  } = useContext(AdminContext);

  const {
    outPlayer,
    inPlayer,
    setInPlayer,
    action,
    addPlayer,
    clearAction,
    substitutePlayer,
    flatList,
  } = useContext(SquadContext);

  const { teams, numberOfTeamPlayers } = useContext(SettingsContext);

  const onClickPlayer = (playerRecord) => {
    const { id, position } = playerRecord;

    if (action !== null)
      setInPlayer({
        playerId: id,
        position: position,
      });

    setSearchPlayer(null);
    setSearchTeam(-1);
    setSearchPosition("All");
  };

  const resetFilters = () => {
    setSearchPlayer(null);
    textInput.current.value = "";
  };

  useEffect(() => {
    if (action === "add" && inPlayer !== null && outPlayer !== null) {
      addPlayer(outPlayer, inPlayer);
      onHide();
    } else if (
      action === "substitute" &&
      inPlayer !== null &&
      outPlayer !== null
    ) {
      substitutePlayer(outPlayer, inPlayer);
    }

    clearAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inPlayer]);

  useEffect(() => {
    const filteredPlayers = players
      .filter((item) => !item.Hidden)
      .filter((item) =>
        searchPlayer
          ? item?.LastName?.toUpperCase().includes(
              searchPlayer?.toUpperCase()
            ) || item?.name?.toUpperCase().includes(searchPlayer?.toUpperCase())
          : true
      )
      .filter((item) =>
        searchPosition !== "All" ? item.position === searchPosition : true
      )
      .filter((item) => (searchTeam !== -1 ? item.teamId === searchTeam : true))
      .sort((a, b) => b[sortKey] - a[sortKey]);
    setFilteredPlayers(filteredPlayers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPlayer, searchPosition, searchTeam, players, sortKey]);

  const onChangeSearchPlayer = (e) => {
    const searchKey = e.target.value;
    if (!searchKey) setSearchPlayer(null);
    if (searchKey.length < 3) return;
    setSearchPlayer(searchKey);
  };

  const onChangePosition = (position) => {
    setSearchPosition(position);
  };

  const onChangeSearchTeam = (teamId) => {
    setSearchTeam(+teamId);
  };

  const columns = React.useMemo(
    () => [
      {
        id: "photo",
        width: 300,
        Cell: (tableProps) => (
          <div className={styles.player}>
            <i
              className={`${styles.info} bi bi-info-circle-fill`}
              onClick={(e) => {
                e.stopPropagation();
                onShowPlayerInfo(tableProps.row.original.PlayerId);
              }}
            ></i>
            <div style={{ position: "relative" }}>
              <img
                style={{
                  objectFit: "contain",
                  borderRadius: "100%",
                  height: 35,
                  width: 35,
                  marginRight: 8,
                }}
                src={tableProps.row.original.photo}
                width={50}
                alt=""
              />
              {tableProps.row.original.isSuspended && (
                <i
                  className={`bi bi-shield-fill-exclamation ${styles.miniExclamation}`}
                ></i>
              )}
            </div>
            <div>
              <div>
                <strong style={{ textTransform: "uppercase" }}>
                  {getPlayerName(tableProps.row.original)}
                </strong>
              </div>
              <div>
                {getShortPosition(tableProps.row.original.position)} |{" "}
                {teams[tableProps.row.original.teamId].shortName}
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: "Ціна",
        accessor: "Cost",
        width: 50,
      },
      {
        Header: "Бали",
        accessor: "Pts",
        width: 50,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-search"></i>&nbsp;ПОШУК ГРАВЦІВ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <Grid fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Row className={styles.row}>
            <Col md={6} className={styles.col}>
              <ButtonGroup className={styles.dropdownContainer}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={getPosition(searchPosition)}
                  style={{ fontSize: "1.3rem", width: "33.3%" }}
                  variant="success"
                >
                  <Dropdown.Item onClick={() => onChangePosition("All")}>
                    Усі
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onChangePosition("Goalkeeper")}
                    active={searchPosition === "Goalkeeper"}
                  >
                    Голкіпер
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onChangePosition("Defender")}
                    active={searchPosition === "Defender"}
                  >
                    Захисник
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onChangePosition("Midfielder")}
                    active={searchPosition === "Midfielder"}
                  >
                    Півзахисник
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onChangePosition("Attacker")}
                    active={searchPosition === "Attacker"}
                  >
                    Нападник
                  </Dropdown.Item>
                </DropdownButton>

                <DropdownButton
                  title={teams[searchTeam]?.name || "Команда"}
                  style={{ marginLeft: 8, fontSize: "1.3rem", width: "33.3%" }}
                  variant="success"
                >
                  <Dropdown.Item onClick={() => onChangeSearchTeam(-1)}>
                    Усі
                  </Dropdown.Item>
                  {getMainLeagueTeams(teams)?.map((item) => (
                    <Dropdown.Item
                      key={item}
                      active={searchTeam === +item}
                      onClick={() => onChangeSearchTeam(item)}
                    >
                      {teams[item].name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <DropdownButton
                  title="Сортувати"
                  style={{ marginLeft: 8, fontSize: "1.3rem", width: "33.3%" }}
                  variant="success"
                >
                  <Dropdown.Item
                    key="Cost"
                    active={sortKey === "Cost"}
                    onClick={() => setSortKey("Cost")}
                  >
                    За ціною
                  </Dropdown.Item>
                  <Dropdown.Item
                    key="Pts"
                    active={sortKey === "Pts"}
                    onClick={() => setSortKey("Pts")}
                  >
                    За балами
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </Col>
            <Col md={6} className={styles.textFldCnt}>
              <Form.Control
                style={{ fontSize: "1.3rem" }}
                ref={textInput}
                type="text"
                placeholder="Пошук за іменем"
                onChange={onChangeSearchPlayer}
              />
              <Button
                style={{ marginLeft: 12, width: "33.3%", fontSize: "1.3rem" }}
                onClick={resetFilters}
              >
                Очистити
              </Button>
            </Col>
          </Row>
          <ListViewContainer
            allowedPosition={action === "add" ? outPlayer?.position : "All"}
            disallowedTeams={getDisallowedTeamIds(
              players,
              flatList,
              numberOfTeamPlayers
            )}
            onClick={onClickPlayer}
            data={filteredPlayers}
            columns={columns}
            players={players}
            setPlayers={setPlayers}
            setFilteredPlayers={setFilteredPlayers}
          />
        </Grid>
      </Modal.Body>
    </Modal>
  );
};

export default PlayerSearch;
