import { useContext } from "react";
import { Offcanvas } from "react-bootstrap";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from "../../providers/SettingsProvider";
import { tgLink } from "../../constants";

const MobileMenu = ({
  show,
  handleClose,
  showSupportPopup = () => undefined,
}) => {
  const navigate = useNavigate();
  const { monthIndex } = useContext(SettingsContext);

  const onItemClick = (action) => {
    handleClose();
    switch (action) {
      case "club-leagues":
        navigate("/leagues");
        break;
      case "private-leagues":
        navigate("/private-leagues");
        break;
      case "month-league":
        navigate(`/standings/${monthIndex}`);
        break;
      case "support":
        showSupportPopup();
        break;
      case "rules":
        navigate(`/rules`);
        break;
      case "telegram":
        window.open(tgLink, "_blank");
        break;
      case "privacy-policy":
        navigate(`/privacy-policy`);
        break;
      case "terms-of-service":
        navigate(`/terms-of-service`);
        break;
      case "logout":
        navigate("/logout");
        break;
      default:
        break;
    }
  };

  return (
    <Offcanvas
      className={styles.canvas}
      show={show}
      onHide={handleClose}
      placement="bottom"
    >
      <Offcanvas.Header
        className={styles.header}
        closeButton
      ></Offcanvas.Header>{" "}
      <Offcanvas.Body className={styles.body}>
        <div
          className={styles.menuItem}
          onClick={() => onItemClick("club-leagues")}
        >
          <i className="bi bi-list-task"></i>&nbsp;Клубні ліги
        </div>
        <div
          className={styles.menuItem}
          onClick={() => onItemClick("private-leagues")}
        >
          <i className="bi bi-incognito"></i>&nbsp;Приватні ліги
        </div>
        <div
          className={styles.menuItem}
          onClick={() => onItemClick("month-league")}
        >
          <i className="bi bi-calendar3"></i>&nbsp;Рейтинг місяця
        </div>
        <hr />
        <div className={styles.menuItem} onClick={() => onItemClick("rules")}>
          <i className="bi bi-paperclip"></i>&nbsp;Правила гри
        </div>
        <div
          className={styles.menuItem}
          onClick={() => onItemClick("terms-of-service")}
        >
          <i className="bi bi-file-check"></i>&nbsp;Угода користувача
        </div>
        <div
          className={styles.menuItem}
          onClick={() => onItemClick("privacy-policy")}
        >
          <i className="bi bi-cookie"></i>&nbsp;Використання cookies
        </div>
        <hr />
        <div
          className={styles.menuItem}
          onClick={() => onItemClick("telegram")}
        >
          <i className="bi bi-telegram"></i>&nbsp;Офіційний канал
        </div>
        <div className={styles.menuItem} onClick={() => onItemClick("support")}>
          <i className="bi bi-headset"></i>&nbsp;Техпідтримка
        </div>
        <hr />
        <div className={styles.menuItem} onClick={() => onItemClick("logout")}>
          <i className="bi bi-box-arrow-right"></i>&nbsp;Вийти
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MobileMenu;
