import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const userIsLogged = localStorage.getItem("access_token");
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (pathname === "/login") return;
    localStorage.setItem("redirectPath", pathname);
    localStorage.setItem("redirectQuery", search);
  }, [pathname, search]);

  if (!userIsLogged) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default PrivateRoute;
