import React, { createContext, useState, useEffect } from "react";
import { getCalculatedTeamIds } from "../helpers/squad";

export const SettingsContext = createContext({
  currentRound: null,
  setCurrentRound: () => undefined,
  roundMatches: [],
  setRoundMatches: () => undefined,
  activeDeadline: false,
  setActiveDeadline: () => undefined,
  standings: [],
  setStandings: () => undefined,
  activePlayersNumber: null,
  setActivePlayersNumber: () => undefined,
  teams: {},
  setTeams: () => undefined,
  calculatedTeamIds: [],
});

function SettingsProvider({ children }) {
  const [currentRound, setCurrentRound] = useState(null);
  const [roundMatches, setRoundMatches] = useState([]);
  const [standings, setStandings] = useState([]);
  const [activePlayersNumber, setActivePlayersNumber] = useState(null);
  const [activeDeadline, setActiveDeadline] = useState(false);
  const [roundDeadline, setRoundDeadline] = useState(null);
  const [teams, setTeams] = useState({});
  const [calculatedTeamIds, setCalculatedTeamIds] = useState([]);
  const [monthIndex, setMonthIndex] = useState(null);
  const [mainInfo, setMainInfo] = useState(null);
  const [monthInfo, setMonthInfo] = useState(null);
  const [prevRoundInfo, setPrevRoundInfo] = useState(null);
  const [leagueInfo, setLeagueInfo] = useState(null);
  const [prevMain, setPrevMain] = useState(null);
  const [prevMonth, setPrevMonth] = useState(null);
  const [prevLeague, setPrevLeague] = useState(null);
  const [maintenanceMode, setMaintenanceMode] = useState(null);
  const [numberOfTeamPlayers, setNumberOfTeamPlayers] = useState(null);
  const [selectedRound, setSelectedRound] = useState(null);

  useEffect(() => {
    const newCalculatedTeamIds = getCalculatedTeamIds(roundMatches);
    setCalculatedTeamIds(newCalculatedTeamIds);
  }, [roundMatches]);

  return (
    <SettingsContext.Provider
      value={{
        currentRound,
        setCurrentRound,
        roundMatches,
        setRoundMatches,
        activeDeadline,
        setActiveDeadline,
        standings,
        setStandings,
        activePlayersNumber,
        setActivePlayersNumber,
        calculatedTeamIds,
        roundDeadline,
        setRoundDeadline,
        teams,
        setTeams,
        monthIndex,
        setMonthIndex,
        mainInfo,
        setMainInfo,
        monthInfo,
        setMonthInfo,
        prevRoundInfo,
        setPrevRoundInfo,
        leagueInfo,
        setLeagueInfo,
        prevMain,
        setPrevMain,
        prevMonth,
        setPrevMonth,
        prevLeague,
        setPrevLeague,
        maintenanceMode,
        setMaintenanceMode,
        numberOfTeamPlayers,
        setNumberOfTeamPlayers,
        selectedRound,
        setSelectedRound,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsProvider;
