import React, { useEffect, useState } from "react";
import Page from "../components/Page";

const Sponsors = () => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const code = params.get("code");

    if (code) {
      setResponse(code);
    }
  }, []);

  const onGetAuthCodeClick = () => {
    const clientId =
      "27541004964-uljda0hn3u9vjc0r8v42chppen99voub.apps.googleusercontent.com";
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${encodeURIComponent(
      window.location.origin + "/sponsors"
    )}&prompt=consent&response_type=code&client_id=${clientId}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.channel-memberships.creator&access_type=offline`;
  };

  return (
    <Page>
      <button onClick={onGetAuthCodeClick}>Get Authorization Code</button>
      <pre>{response}</pre>
    </Page>
  );
};

export default Sponsors;
