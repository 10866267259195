import React from "react";
import Page from "../components/Page";
import ScrollToTop from "../components/ScrollToTop";
import { patreonUrl, youtubeUrl } from "../constants";
import { Button } from "react-bootstrap";

const AccessDenied = () => {
  return (
    <Page>
      <ScrollToTop />
      <h4 style={{ margin: "12px 0" }}>
        <i className="bi bi-gear"></i>&nbsp;Доступ обмежено
      </h4>
      <img
        src="/maintenance.jpeg"
        style={{ width: "100%", height: 270, objectFit: "cover" }}
        alt="Poster"
      />
      <p style={{ marginTop: 12 }}>
        Для того щоб продовжити грати у{" "}
        <strong>{process.env.REACT_APP_FANTASY_TITLE}</strong> вам необхідно
        стати патроном{" "}
        <a href={youtubeUrl} rel="noreferrer" target="_blank">
          KDK
        </a>
        .
        <br />
        <br />✅ Перейдіть на{" "}
        <a href={patreonUrl} rel="noreferrer" target="_blank">
          Patreon
        </a>{" "}
        <br />✅ Підпишіться як мінімум на таєр{" "}
        <strong>"Гравець Фентезі УПЛ"</strong>.<br /> ✅ За 20-30 хвилин
        повертайтеся та насолоджуйтеся грою.{" "}
      </p>
      <Button
        style={{ fontSize: "1.2rem" }}
        onClick={() => window.open(patreonUrl, "_blank")}
      >
        <i className="bi bi-caret-right-fill"></i>&nbsp;Перейти на Patreon
      </Button>
    </Page>
  );
};

export default AccessDenied;
