import React, { useState, useEffect } from "react";
import API from "../api";
import BackButton from "../components/BackButton";
import Page from "../components/Page";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import RoundDetailsView from "../components/RoundDetailsView";
import { Badge } from "react-bootstrap";

const RoundDetails = () => {
  const [loading, setLoading] = useState(false);
  const [bestPlayers, setBestPlayers] = useState(null);

  const { roundNumber } = useParams();

  const fetchRoundDetails = async (roundNumber) => {
    setLoading(true);
    try {
      const res = await API.fetchRoundInfo(roundNumber);
      setBestPlayers(res.data.best);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoundDetails(roundNumber);
    //eslint-disable-next-line
  }, [roundNumber]);

  return (
    <Page>
      <ScrollToTop />
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="page-bar">
          <div className="inner">
            <div style={{ display: "flex", alignItems: "center" }}>
              <BackButton />
              {bestPlayers && (
                <div
                  style={{
                    margin: "4px 8px",
                    fontWeight: "bold",
                    display: "flex",
                    fontSize: "1.3rem",
                    lineHeight: "1.3rem",
                    alignItems: "center",
                  }}
                >
                  ЗБІРНА {roundNumber} ТУРУ
                  <div style={{ position: "relative", top: -1 }}>
                    <Badge
                      style={{ fontSize: "1rem", marginLeft: 8 }}
                      bg="success"
                    >
                      {bestPlayers?.allPoints}
                    </Badge>
                    <Badge
                      style={{ fontSize: "1rem", marginLeft: 8 }}
                      bg="danger"
                    >
                      {bestPlayers?.Details.reduce(
                        (acc, current) => acc + current.Cost,
                        0
                      ).toFixed(1)}{" "}
                      $
                    </Badge>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        bestPlayers !== null && (
          <RoundDetailsView
            squad={bestPlayers?.Squad}
            details={bestPlayers?.Details}
            round={bestPlayers?.Round}
          />
        )
      )}
    </Page>
  );
};

export default RoundDetails;
