import React, { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import styles from "./player-item.module.scss";
import Select from "react-select";

const PlayerItem = ({
  player,
  isHome,
  item,
  playersList,
  dispatch,
  index,
  playerType,
  isAdminMode,
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  useEffect(() => {
    const targetItem = playersList.find(
      (entry) => entry.value === item.player?.id
    );
    setSelectedPlayer(targetItem);
  }, [item, playersList]);

  const onPlayerChange = (selectedOption, index, playerType) => {
    const playerItem =
      playersList.find((entry) => entry.value === selectedOption.value) ?? null;
    setSelectedPlayer(playerItem);
    onUpdatePlayer(index, playerType, playerItem.value);
  };

  const onRemovePlayer = (playerIndex, playerType) => {
    dispatch({
      type: "removePlayer",
      payload: { playerIndex, playerType },
    });
  };

  const onUpdatePlayer = (playerIndex, playerType, playerId) => {
    dispatch({
      type: "updatePlayer",
      payload: { playerIndex, playerType, playerId },
    });
  };

  return (
    <div className={`${isHome ? styles.homeCnt : styles.awayCnt}`}>
      <>
        {isAdminMode && (
          <Badge className={styles.badge}>{player?.number || "-"}</Badge>
        )}
        <div className={styles.playerCnt}>
          <Select
            maxMenuHeight={200}
            menuPlacement="top"
            className={`${styles.dropdown} ${
              !isAdminMode ? styles.viewDropdown : null
            }`}
            value={selectedPlayer}
            options={playersList}
            onChange={(selectedOption) =>
              onPlayerChange(selectedOption, index, playerType)
            }
            isDisabled={!isAdminMode}
          />
          {isAdminMode && (
            <i
              className={`bi bi-trash-fill ${styles.hoverable}`}
              onClick={() => onRemovePlayer(index, playerType)}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default PlayerItem;
