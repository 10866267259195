import React from "react";
import Page from "../Page";
import BackButton from "../BackButton";
import ScrollToTop from "../ScrollToTop";
import CookieBotDeclaration from "../CookieBotDeclaration";

const PrivacyPolicy = () => {
  return (
    <Page>
      <ScrollToTop />
      <div className="page-bar">
        <div className="inner">
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackButton />
            <h4 className="bar-title">Використання cookies</h4>
          </div>
        </div>
      </div>
      <div className="page-offset">
        <CookieBotDeclaration />
      </div>
    </Page>
  );
};

export default PrivacyPolicy;
