import styles from "./index.module.scss";
import { Badge } from "react-bootstrap";
import CustomAnimatedNumber from "../CustomAnimatedNumber";

const Pin = ({
  topCaption = "",
  leftSideText = "",
  rightSideText = "",
  onClick = () => undefined,
  width = 109,
}) => {
  return (
    <div style={{ width: width }}>
      <span className={styles.caption}>{topCaption}</span>
      <Badge
        className={`${styles.lightTextBadge} ${styles.badge} ${styles.hoverable} ${styles.left}`}
        bg="success"
        onClick={onClick}
      >
        {leftSideText}
        <div className={styles.rightSide}>
          <CustomAnimatedNumber delay={500} value={rightSideText} />
        </div>
      </Badge>
    </div>
  );
};

export default Pin;
