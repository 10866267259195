export default function jsonValidate(fixture) {
  let meta = {
    isValid: true,
    errorMsg: null,
    summary: {},
  };
  if (fixture.lineups.length !== 2) {
    meta.isValid = false;
    meta.errorMsg = "Склади команд недоступні.";
  } else if (fixture.events.length === 0) {
    meta.isValid = false;
    meta.errorMsg = "Події матчу недоступні.";
  } else if (
    fixture.lineups[0].startXI.length !== 11 ||
    fixture.lineups[1].startXI.length !== 11
  ) {
    meta.isValid = false;
    meta.errorMsg = "Проблема зі стартовим складом";
  } else if (
    fixture.lineups[0].startXI
      .concat(fixture.lineups[1].startXI)
      .concat(fixture.lineups[0].substitutes)
      .concat(fixture.lineups[1].substitutes)
      .filter((player) => player.id === null).length > 0
  ) {
    meta.isValid = false;
    meta.errorMsg = "Нульовий айдішник гравця";
  } else if (
    fixture.events
      .filter((ev) => ev.type === "subst")
      .filter((ev) => ev.player.id === null || ev.assist.id === null).length > 0
  ) {
    meta.isValid = false;
    meta.errorMsg = "Нульовий айдішник заміни";
  } else if (
    fixture.events
      .filter((ev) => ev.type === "Card")
      .filter((ev) => ev.player.id === null).length > 0
  ) {
    meta.isValid = false;
    meta.errorMsg = "Нульовий айдішник картки";
  } else if (
    fixture.events
      .filter((ev) => ev.type === "Goal")
      .filter((ev) => ev.player.id === null).length > 0
  ) {
    meta.isValid = false;
    meta.errorMsg = "Нульовий айдішник голу";
  } else if (
    fixture.events
      .filter((ev) => ev.type === "MVP")
      .filter((ev) => ev.player.id === null).length > 0
  ) {
    meta.isValid = false;
    meta.errorMsg = "Нульовий айдішник MVP";
  } else if (
    fixture.events
      .filter((ev) => ev.type === "Saves")
      .filter((ev) => ev.player.id === null).length > 0
  ) {
    meta.isValid = false;
    meta.errorMsg = "Нульовий айдішник сейвів";
  } else {
    meta.summary.subst = fixture.events.filter(
      (ev) => ev.type === "subst"
    ).length;

    meta.summary.redcards = fixture.events.filter(
      (ev) => ev.detail === "Red Card"
    ).length;
    meta.summary.yellowcards = fixture.events.filter(
      (ev) => ev.detail === "Yellow Card"
    ).length;
    meta.summary.goals = fixture.events.filter(
      (ev) => ev.type === "Goal" && ev.detail !== "Missed Penalty"
    ).length;
    meta.summary.numplayers =
      meta.summary.subst +
      fixture.lineups[0].startXI.length +
      fixture.lineups[1].startXI.length;
  }

  return meta;
}
