import React, { createContext, useState } from "react";
import { Alert } from "react-bootstrap";

export const MessageContext = createContext({
  showMessage: () => "",
});

const styles = {
  position: "fixed",
  left: 8,
  right: 8,
  zIndex: 1,
  maxWidth: 1280,
  margin: "0 auto",
  textTransform: "uppercase",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",
  fontWeight: "bold",
};

function MessageProvider({ children }) {
  const [text, setText] = useState("");
  const [type, setType] = useState("primary");
  const [hidden, setHidden] = useState(true);

  const showMessage = (type = "primary", text = "") => {
    setHidden(false);
    setType(type);
    setText(text);
    setTimeout(() => {
      setHidden(true);
    }, 5000);
  };

  return (
    <MessageContext.Provider value={{ showMessage }}>
      <Alert
        className={hidden ? "alert-hidden" : "alert-visible"}
        variant={type}
        style={styles}
      >
        {text}
      </Alert>

      {children}
    </MessageContext.Provider>
  );
}

export default MessageProvider;
