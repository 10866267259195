import React from "react";
import styles from "./field.module.scss";

const Field = ({ children, caption, style = {} }) => {
  return (
    <div className={styles.cnt}>
      <div className={styles.caption}>{caption}</div>
      <div style={style}>{children}</div>
    </div>
  );
};

export default Field;
