import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import styles from "./event.module.scss";
import Field from "./field";

const timeOptions = Array.from(Array(90).keys())
  .map((item) => {
    ++item;
    return { label: String(item), value: Number(item) };
  })
  .concat({ label: "-", value: null });

const eventTypes = [
  { label: "Заміна", value: "subst" },
  { label: "Гол", value: "Goal" },
  { label: "Картка", value: "Card" },
  { label: "Пенальті", value: "Penalty" },
  { label: "Гравець матчу", value: "MVP" },
  { label: "Сейви", value: "Saves" },
];
const details = [
  { type: "Card", value: "Yellow Card", label: "Жовта" },
  { type: "Card", value: "Red Card", label: "Червона" },
  { type: "subst", value: "Substitution", label: "Заміна" },
  { type: "Goal", value: "Normal Goal", label: "Звичайний" },
  { type: "Goal", value: "Own Goal", label: "Автогол" },
  { type: "Goal", value: "Penalty", label: "Пенальті" },
  { type: "Goal", value: "Missed Penalty", label: "Нереалізований пенальті" },
  { type: "MVP", value: "MVP1", label: "Гравець матчу 1" },
  { type: "MVP", value: "MVP2", label: "Гравець матчу 2" },
  { type: "MVP", value: "MVP3", label: "Гравець матчу 3" },
  { type: "Saves", value: "0", label: "0" },
  { type: "Saves", value: "1", label: "1" },
  { type: "Saves", value: "2", label: "2" },
  { type: "Saves", value: "3", label: "3" },
  { type: "Saves", value: "4", label: "4" },
  { type: "Saves", value: "5", label: "5" },
  { type: "Saves", value: "6", label: "6" },
  { type: "Saves", value: "7", label: "7" },
  { type: "Saves", value: "8", label: "8" },
  { type: "Saves", value: "9", label: "9" },
  { type: "Saves", value: "10", label: "10" },
];

const EventInfo = ({ event, playersList, fixtureTeams, dispatch, index }) => {
  const { time, player, assist, type, detail, team } = event;
  const [formTimestamp, setFormTimestamp] = useState(null);
  const [eventElapsedTime, setEventElapsedTime] = useState(null);
  const [eventExtraTime, setEventExtraTime] = useState(null);
  const [eventPlayer, setEventPlayer] = useState(null);
  const [eventAssist, setEventAssist] = useState(null);
  const [eventTeam, setEventTeam] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [eventDetails, setEventDetails] = useState([]);
  const [eventDetail, setEventDetail] = useState(null);

  const formInitialized = useRef(false);

  const dispatchEvent = () => {
    const newEvent = {
      time: {
        elapsed: eventElapsedTime.value,
        extra: eventExtraTime.value,
      },
      team: {
        id: eventTeam.value,
      },
      player: {
        id: eventPlayer?.value,
      },
      assist: {
        id: eventAssist?.value,
      },
      type: eventType.value,
      detail: eventDetail?.value,
    };

    dispatch({
      type: "updateEvent",
      payload: { eventIndex: index, eventData: newEvent },
    });
  };

  useEffect(() => {
    if (!formTimestamp || !eventTeam) return;
    dispatchEvent();
    // eslint-disable-next-line
  }, [formTimestamp, eventTeam]);

  useEffect(() => {
    const timeElapsedOption = timeOptions.find(
      (entry) => entry.value === time?.elapsed
    );
    const timeExtraOption = timeOptions.find(
      (entry) => entry.value === time?.extra
    );

    setEventElapsedTime(timeElapsedOption);
    setEventExtraTime(timeExtraOption);
  }, [time]);

  useEffect(() => {
    const targetDetail = details.find((entry) => entry.value === detail);
    setEventDetail(targetDetail);
  }, [detail]);

  useEffect(() => {
    const targetEvent = eventTypes.find((types) => types.value === type);
    setEventType(targetEvent);
  }, [type]);

  useEffect(() => {
    const targetTeam = fixtureTeams.find((types) => types.value === team.id);
    setEventTeam(targetTeam);
  }, [team, fixtureTeams]);

  useEffect(() => {
    const targetPlayer = playersList.find((entry) => entry.value === player.id);
    setEventPlayer(targetPlayer);
  }, [player, playersList]);

  useEffect(() => {
    const targetAssist = playersList.find((entry) => entry.value === assist.id);
    setEventAssist(targetAssist);
  }, [assist, playersList]);

  const onChangeEventType = async (selectedOption) => {
    const targetEvent = eventTypes.find(
      (types) => types.value === selectedOption.value
    );
    setEventType(targetEvent);
    if (targetEvent.value === "Card") {
      setEventAssist(null);
    }
  };

  const onChangeEventDetail = async (selectedOption) => {
    const targetDetail = details.find(
      (types) => types.value === selectedOption.value
    );
    await setEventDetail(targetDetail);
    setFormTimestamp(+new Date());
  };

  const onInputEventDetail = async (event) => {
    await setEventDetail(event.target.value);
    setFormTimestamp(+new Date());
  };

  const onPlayerChange = async (selectedOption) => {
    const targetDetail =
      playersList.find((entry) => entry.value === selectedOption.value) ?? null;
    await setEventPlayer(targetDetail);
    setFormTimestamp(+new Date());
  };

  const onAssistChange = async (selectedOption) => {
    const targetDetail =
      playersList.find((entry) => entry.value === selectedOption.value) ?? null;
    await setEventAssist(targetDetail);
    setFormTimestamp(+new Date());
  };

  const onTeamChange = async (selectedOption) => {
    const targetDetail = fixtureTeams.find(
      (entry) => entry.value === selectedOption.value
    );
    await setEventTeam(targetDetail);
    setFormTimestamp(+new Date());
  };

  const onElapsedTimeChange = async (selectedOption) => {
    const targetOption = timeOptions.find(
      (option) => option.value === selectedOption.value
    );
    await setEventElapsedTime(targetOption);
    setFormTimestamp(+new Date());
  };

  const onExtraTimeChange = async (selectedOption) => {
    const targetOption = timeOptions.find(
      (option) => option.value === selectedOption.value
    );
    await setEventExtraTime(targetOption);
    setFormTimestamp(+new Date());
  };

  useEffect(() => {
    if (!eventDetail || !eventType) return;
    const targetDetails = details.filter(
      (entry) => entry.type === eventType?.value
    );

    setEventDetails(targetDetails);
    setEventDetail(
      eventDetail?.type !== eventType?.value ? targetDetails[0] : eventDetail
    );

    if (formInitialized.current) {
      setFormTimestamp(+new Date());
    }

    formInitialized.current = true;
  }, [eventType, eventDetail]);

  return (
    <div className={styles.cnt}>
      <div className={styles.row}>
        <div className={styles.col}>
          <Field caption="Elapsed" style={{ marginRight: 8 }}>
            <Select
              className={styles.dropdown}
              value={eventElapsedTime}
              options={timeOptions}
              onChange={onElapsedTimeChange}
            />
          </Field>
          <Field caption="Extra">
            <Select
              className={styles.dropdown}
              onChange={onExtraTimeChange}
              value={eventExtraTime}
              options={timeOptions}
            />
          </Field>
        </div>
        <div className={styles.col}>
          <Field caption="Team">
            <Select
              className={styles.dropdown}
              value={eventTeam}
              options={fixtureTeams}
              onChange={onTeamChange}
            />
          </Field>
        </div>
        <div className={styles.col}>
          <Field caption="Type">
            <Select
              className={styles.dropdown}
              value={eventType}
              onChange={onChangeEventType}
              options={eventTypes}
            />
          </Field>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Field caption="Player">
            <Select
              className={styles.dropdown}
              value={eventPlayer}
              options={playersList}
              onChange={onPlayerChange}
            />
          </Field>
        </div>
        <div className={styles.col}>
          <Field caption="Assist">
            <Select
              className={styles.dropdown}
              value={eventAssist}
              options={playersList}
              onChange={onAssistChange}
            />
          </Field>
        </div>
        <div className={styles.col}>
          {eventDetails.length > 1 ? (
            <Field caption="Detail">
              <Select
                className={styles.dropdown}
                value={eventDetail}
                onChange={onChangeEventDetail}
                options={eventDetails}
              />
            </Field>
          ) : (
            <Field caption="Detail">
              <input
                className={styles.textInput}
                type="text"
                defaultValue={eventDetails?.[0]?.value}
                onChange={onInputEventDetail}
              />
            </Field>
          )}
        </div>
      </div>
      <div className={styles.trashCnt}>
        <span
          className={styles.remove}
          onClick={() => {
            dispatch({
              type: "removeEvent",
              payload: { eventIndex: index },
            });
          }}
        >
          <i className="bi bi-trash-fill"></i>&nbsp;Видалити
        </span>
      </div>
    </div>
  );
};

export default EventInfo;
