import Axios from "axios";
import API, { getAuthType } from "./index";
import { clearLocalStorage } from "../helpers/user";

const interceptor = () => {
  Axios.interceptors.request.use(
    (conf) => {
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  Axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      const status = error.response && error.response.status;
      const originalRequest = error.config;

      if (status > 499) {
        console.log("show error message");

        setTimeout(() => {
          console.log("error message has been hidden");
        }, 5000);
      }

      if (status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        return API.refreshAuthToken()
          .then(({ data }) => {
            if (data.access_token && data.refresh_token) {
              localStorage.setItem("access_token", data.access_token);
              localStorage.setItem("refresh_token", data.refresh_token);
              Axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.access_token;
              originalRequest.headers["Authorization"] =
                "Bearer " + data.access_token;
            }
            return Axios(originalRequest);
          })
          .catch(() => (window.location.href = "/logout"));
      }

      if (status === 403) {
        if (getAuthType() === "patreon") {
          window.location.href = "/access-denied";
        }
      }

      if (status === 503) {
        clearLocalStorage();
        window.location.href = "/maintenance";
      }

      return Promise.reject(error);
    }
  );
};
export default interceptor;
