import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Button
      size="sm"
      variant="success"
      onClick={goBack}
      style={{
        fontSize: "1.3rem",
        visibility: !location.key ? "hidden" : "visible",
      }}
    >
      &#x2190;&nbsp;Назад
    </Button>
  );
};

export default BackButton;
