import React from "react";
import styles from "./event-view.module.scss";
import { getPlayerLastName, getPlayerById } from "../../helpers/player";
import { Badge } from "react-bootstrap";

const getEventTypeIcon = ({ type, detail }) => {
  if (type === "Goal") {
    if (detail === "Penalty") {
      return <Badge className={styles.description}>Пен</Badge>;
    } else if (detail === "Missed Penalty") {
      return (
        <Badge
          bg="danger"
          style={{ color: "#fff" }}
          className={styles.description}
        >
          Пен
        </Badge>
      );
    } else if (detail === "Own Goal") {
      return (
        <Badge
          bg="danger"
          style={{ color: "#fff" }}
          className={styles.description}
        >
          АГ
        </Badge>
      );
    } else {
      return <Badge className={styles.description}>Гол</Badge>;
    }
  } else if (type === "Card") {
    return (
      <i
        className="bi bi-square-fill"
        style={{
          fontSize: 24,
          color: detail === "Yellow Card" ? "rgb(255, 224, 0)" : "red",
        }}
      />
    );
  } else if (type === "subst") {
    return (
      <Badge bg="warning" className={styles.description}>
        Зам
      </Badge>
    );
  } else if (type === "Penalty") {
    return (
      <Badge bg="warning" className={styles.description}>
        Пен
      </Badge>
    );
  } else if (type === "MVP") {
    return <i className={`bi bi-award`} style={{ fontSize: 24 }} />;
  } else if (type === "Saves") {
    return <i className={`bi bi-${detail}-square`} style={{ fontSize: 24 }} />;
  }
};

const EventView = ({ event, players = [], fixtureTeams = [] }) => {
  const elapsedTime = event?.time?.elapsed;
  const extraTime = event?.time?.extra ? `+${event?.time?.extra}` : ``;

  const playerName = getPlayerLastName(
    getPlayerById(players, event?.player?.id)
  );

  const assistName = getPlayerLastName(
    getPlayerById(players, event?.assist?.id)
  );

  const isSubstitution = event?.type === "subst";
  const isGoal = event?.type === "Goal";
  const isPenalty = event?.type === "Penalty";

  const isMVP = event?.type === "MVP";
  const isSaves = event?.type === "Saves";

  const isAwayTeam = event?.team?.id === fixtureTeams[1]?.value;

  const getFirstItem = () => {
    if (elapsedTime) {
      return (
        <>
          <i className="bi bi-clock" style={{ fontSize: 16 }}></i>&nbsp;
          {elapsedTime}
          {extraTime}
        </>
      );
    } else if (isMVP) {
      return <Badge className="darkText">{event.detail}</Badge>;
    } else if (isSaves) {
      return <Badge className="darkText">СЕЙВИ</Badge>;
    }
  };

  if (!event || !fixtureTeams.length)
    return <div className={styles.event}></div>;

  return (
    <div className={`${styles.event} ${isAwayTeam ? styles.end : null}`}>
      <div className={styles.wrapper}>
        <div className={styles.leftCol}>
          <div className={styles.row}>{getFirstItem()}</div>
        </div>

        <div className={styles.col}>
          <div className={styles.players}>
            <div className={styles.name}>
              {playerName}
              {isSubstitution && (
                <i
                  className="bi bi-arrow-up-circle-fill"
                  style={{ color: "green", marginRight: 8 }}
                />
              )}
            </div>
            <div className={styles.name}>
              <span className={`${isGoal || isPenalty ? styles.grey : null}`}>
                {assistName}
              </span>

              {isSubstitution && (
                <i
                  className="bi bi-arrow-down-circle-fill"
                  style={{ color: "red", marginRight: 8 }}
                />
              )}
            </div>
          </div>
          <div>{getEventTypeIcon(event)}</div>
        </div>
      </div>
    </div>
  );
};

export default EventView;
