import React, { useEffect, useState, useContext } from "react";
import { Modal, Badge, Placeholder } from "react-bootstrap";
import {
  getPlayerShortTeam,
  getShortPosition,
  getRivalTeamIdByMatchId,
  isThick,
  getRivalIds,
} from "../../helpers/player";
import { SettingsContext } from "../../providers/SettingsProvider";
import styles from "./index.module.scss";
import API from "../../api";
import { useQuery } from "react-query";
import { getRuleText } from "../../helpers/squad";
import Message from "../../components/Message";
import { getTeamLogoUrl, rndInt, getPlayerPhotoUrl } from "../../helpers/utils";
import CustomAnimatedNumber from "../../components/CustomAnimatedNumber";

const getPlayerSumPts = (playerStats = []) => {
  return playerStats.reduce((acc, value) => acc + value.Pts, 0);
};

const getRoundPts = (playerStats = [], round) => {
  return playerStats.find((stat) => stat.Round === round)?.Pts || "0";
};

const getSelectedRoundInfo = (playerStats = [], round) => {
  return playerStats.find((stat) => stat.Round === round);
};

const isSelectedRound = (selectedRound, round) => {
  return round === selectedRound;
};

const PlayerInfo = ({ show, onHide, player }) => {
  const [playerStats, setPlayerStats] = useState(null);
  const [sumPts, setSumPts] = useState(0);
  const [allRoundsList, setAllRoundsList] = useState([]);
  const [selectedRoundInfo, setSelectedRoundInfo] = useState(null);
  const [selectedRound, setSelectedRound] = useState(1);

  const { currentRound, calculatedTeamIds, teams } = useContext(
    SettingsContext
  );

  useEffect(() => {
    setAllRoundsList(
      Array.from(Array(currentRound).keys())
        .map((Item) => Item + 1)
        .sort((a, b) => b - a)
    );
  }, [currentRound]);

  const isCalculated = calculatedTeamIds.includes(player?.teamId);

  useQuery(
    "query-player-stats",
    async () => {
      return await API.fetchPlayerStats(player.PlayerId);
    },
    {
      enabled: show === true,
      staleTime: 1,
      onSuccess: (res) => {
        setPlayerStats(
          res.data.map((Item) => ({
            ...Item,
            Details: JSON.parse(Item.Details),
          }))
        );
      },
      onError: (err) => {
        console.log(err.response?.data || err);
      },
    }
  );

  useEffect(() => {
    if (!playerStats?.length || currentRound === null) return;
    const newSumPts = getPlayerSumPts(playerStats);
    const newSelectedRoundInfo = getSelectedRoundInfo(
      playerStats,
      currentRound
    );
    setSumPts(newSumPts);
    setSelectedRoundInfo(newSelectedRoundInfo);
    setSelectedRound(currentRound);
  }, [playerStats, currentRound]);

  const onClickRoundItem = (round) => {
    setSelectedRoundInfo(getSelectedRoundInfo(playerStats, round));
    setSelectedRound(round);
  };

  const hideModal = () => {
    onHide();

    setTimeout(() => {
      setSelectedRoundInfo(null);
      setSelectedRound(null);
      setSumPts(0);
      setPlayerStats(null);
    }, 200);
  };

  const isDataAvailable = playerStats !== null;

  return (
    <Modal
      show={show}
      size="lg"
      onHide={hideModal}
      backdrop={true}
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styles.photoCnt}>
            <img
              className={styles.photo}
              src={getPlayerPhotoUrl(player)}
              alt=""
            />
            <div className={styles.info}>
              <div className={styles.name}>
                <div>{player?.FirstName}</div>
                <div>{player?.LastName}</div>
              </div>
              <div className={styles.hint}>
                {getPlayerShortTeam(teams, player)} |{" "}
                {getShortPosition(player?.position)}
                <div className={styles.age}>ВІК {player?.age}</div>
              </div>
            </div>
          </div>
          <div className={styles.panel}>
            <div className={styles.col}>
              <span className={styles.caption}>За сезон</span>
              <Badge
                className={`${styles.darkTextBadge} ${styles.badge}`}
                bg={"primary"}
              >
                <CustomAnimatedNumber value={sumPts} />
              </Badge>
            </div>
            <div className={styles.col}>
              <span className={styles.caption}>Ціна</span>
              <Badge
                className={`${styles.lightTextBadge} ${styles.badge}`}
                bg={"success"}
              >
                {player?.Cost?.toFixed(1)}&nbsp;
                <i className="bi bi-coin" />
              </Badge>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "70vh" }}>
        {isDataAvailable ? (
          <div className={styles.listContainer}>
            {allRoundsList.map((round) => (
              <div
                key={round}
                className={`${styles.listItem} ${
                  isSelectedRound(selectedRound, round) ? styles.selected : ""
                }`}
                onClick={() => onClickRoundItem(round)}
              >
                <div className={styles.round}>ТУР {round}</div>
                <div className={styles.pts}>
                  {" "}
                  {!isCalculated && round === currentRound ? (
                    <i className="bi bi-hourglass darkText"></i>
                  ) : (
                    <Badge className={styles.ptsBadge} bg="warning">
                      {getRoundPts(playerStats, round)}
                    </Badge>
                  )}
                </div>
                <div className={styles.rivalCnt}>
                  {getRivalIds(playerStats, round)?.map((rivalId) => (
                    <img
                      className={styles.rivalLogo}
                      src={getTeamLogoUrl(rivalId, rndInt())}
                      alt=""
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.listContainer}>
            <Placeholder
              as="p"
              animation="glow"
              style={{ display: "flex", height: 96 }}
            >
              <Placeholder xs={1} className style={{ width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
              <Placeholder xs={1} style={{ marginLeft: 8, width: 80 }} />
            </Placeholder>
          </div>
        )}
        {selectedRoundInfo?.Details?.length > 0 ? (
          <div className={styles.detailsContainer}>
            <div className={styles.detailsItem}>
              <div className={styles.key} style={{ fontWeight: "bold" }}>
                Правила
              </div>
              <div className={styles.value}>Бали</div>
            </div>
            {selectedRoundInfo?.Details?.map((item, index, items) => (
              <div
                key={item.PlayerId}
                className={`${styles.detailsItem} ${
                  isThick(item.id, items, index) ? styles.thick : ""
                }`}
              >
                <div className={styles.key}>
                  <img
                    className={styles.rivalLogo}
                    src={getTeamLogoUrl(
                      getRivalTeamIdByMatchId(
                        item.id,
                        items,
                        getRivalIds(playerStats, selectedRound),
                        index
                      ),
                      rndInt()
                    )}
                    alt=""
                  />
                  {getRuleText(item.key)}
                </div>
                <div className={styles.value}> {item.value} </div>
              </div>
            ))}
          </div>
        ) : (
          isDataAvailable && (
            <div className={styles.msgCnt}>
              <Message text="Немає даних" />{" "}
            </div>
          )
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PlayerInfo;
