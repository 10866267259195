import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../api";
import { Button, Spinner } from "react-bootstrap";

const EditMatchDate = ({ match, currentRound }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const { Deadline, Calculated } = match;

  useEffect(() => {
    if (selectedDate === null) setSelectedDate(new Date(Deadline));
  }, [Deadline, selectedDate]);

  const onChangeDate = async (date) => {
    setSelectedDate(new Date(date));
    const payload = {
      ...match,
      Deadline: +new Date(date),
    };
    await API.editFixture("changeDate", payload, currentRound);
  };

  const moveFixture = async () => {
    setLoading(true);
    await API.editFixture("move", match, currentRound);
    setLoading(false);
  };

  return (
    <>
      <DatePicker
        showTimeSelect
        dateFormat="dd.MM.YYYY HH:mm"
        selected={selectedDate}
        onChange={(date) => onChangeDate(date)}
      />

      <Button
        className={Calculated ? `invisible` : ``}
        onClick={moveFixture}
        variant="danger"
        size="sm"
        style={{ marginLeft: 8 }}
      >
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <i className="bi bi-arrows"></i>
        )}
      </Button>
    </>
  );
};

export default EditMatchDate;
