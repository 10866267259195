import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../api";
import { useAuth0, LocalStorageCache } from "@auth0/auth0-react";
import { isAuth0 } from "../helpers/auth";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const AuthContext = createContext({
  user: {},
  loaded: false,
  doAuth: () => new Promise(),
});

function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const doAuth = async () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const code = params.get("code");
    const auth0 = isAuth0();

    try {
      let lsValue;

      while (auth0 && lsValue === undefined) {
        await wait(100);
        const refresh_token = new LocalStorageCache();
        const key = refresh_token
          .allKeys()
          .find((key) => key.includes("auth0spa"));
        lsValue = refresh_token.get(key);
      }

      const isAccessTokenExpired = lsValue?.expiresAt < +new Date() / 1000;

      if (
        auth0 &&
        (!localStorage.getItem("access_token") || isAccessTokenExpired)
      ) {
        const access_token = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
            scope: "read:current_user",
          },
        });
        localStorage.setItem("access_token", access_token);
      }

      if (
        (!code && !localStorage.getItem("access_token")) ||
        pathname === "/support" ||
        pathname === "/maintenance" ||
        pathname === "/access-denied"
      )
        return;

      setLoading(true);

      const response = await API.fetchUserInfo(auth0 ? null : code);

      const userInfo = response.data;
      setUser(userInfo);
      setLoaded(true);

      if (userInfo.access_token) {
        localStorage.setItem("access_token", userInfo.access_token);
      }

      if (userInfo.refresh_token) {
        localStorage.setItem("refresh_token", userInfo.refresh_token);
      }

      if (userInfo.expires_in) {
        const timePoint =
          new Date().getTime() + (userInfo.expires_in - 60) * 1000;
        localStorage.setItem("expires_at", new Date(timePoint));
      }

      if (code) {
        const path = localStorage.getItem("redirectPath") || "/home";
        const query = String(localStorage.getItem("redirectQuery"));
        navigate(`${path}` + query);
      }

      setLoading(false);
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  };

  useEffect(() => {
    doAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        loaded,
        doAuth,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
